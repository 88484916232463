import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { Project } from '../database/models/project';
import { Script } from '../database/models/script';
import { Asset } from '../database/models/asset';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class BitbybitSService {

    selectionBundleSubject$ = new BehaviorSubject<{
        publicUserName: string,
        projectId: string,
        scriptId: string,
        project: Project,
        script: Script,
        assets: Asset[]
    }>({
        publicUserName: '',
        projectId: '',
        scriptId: '',
        script: undefined,
        project: undefined,
        assets: undefined,
    });

    private snapshot: {
        publicUserName: string;
        projectId: string;
        scriptId: string;
        project: Project;
        script: Script;
        assets: Asset[];
    };


    editorReady$;
    receivedScriptForAutoSave$ = new Subject<Script>();

    constructor(
        private http: HttpClient
    ) { }

    initialise() {
        this.selectionBundleSubject$.subscribe(res => {
            this.snapshot = res;
        });

        window.onmessage = (e) => {
            if (e.origin === environment.app) {
                const data = e.data;
                if (data.status === 'link') {
                    if ((data.url as string).includes('https://docs.bitbybit.dev', 0)) {
                        window.open(data.url, '_blank');
                    }
                }
                if (data.status === 'initialised') {
                    this.editorReady$.next(true);
                }
                if (data.status === 'sendScriptForAutoSave') {
                    this.receivedScriptForAutoSave$.next(data.script);
                }
                if (data.action && data.action.functionName) {
                    try {
                        // result can be an observable as certain operations may need to go to backend
                        this[data.action.functionName](data.action.inputs).then(result => {
                            let error;
                            if (!result) {
                                error = `Failed to executre the function '${data.action.functionName}'. Request provided no results.`
                            }

                            (document.getElementById('app-iframe') as HTMLIFrameElement).contentWindow.postMessage(
                                {
                                    result,
                                    error,
                                    uid: data.uid,
                                }, environment.app
                            );
                        });
                    }
                    catch (err) {
                        (document.getElementById('app-iframe') as HTMLIFrameElement).contentWindow.postMessage(
                            {
                                error: err.message,
                                uid: data.uid,
                            }, environment.app
                        );
                    }
                }
            } 
        };
    }

    openScript(script: Script): void {
        this.post({ script, type: 'openScript' });
    }

    getScriptForAutoSave(): void {
        this.post({ type: 'getScript' });
    }

    cleanScriptsAndCanvas(): void {
        this.post({ type: 'cleanScriptAndCanvas' });
    }

    private post<T>(message: T): void {
        (document.getElementById('app-iframe') as HTMLIFrameElement).contentWindow.postMessage(
            message, environment.app
        );
    }

    getAsset(inputs: { fileName: string }): Promise<any> {
        // there is always a single asset file
        const asset = this.snapshot.assets.find(a => a.fileName === inputs.fileName);
        if (asset) {
            return this.http.get<any>(asset.downloadUrl, { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((result: HttpResponse<Blob>) => {
                    return new File([result.body], asset.fileName);
                })).toPromise();
        } else {
            return of(undefined).toPromise();
        }
    }
}
