<h1>Sign Up</h1>
<div class="info">
    In order to sign up and use our services you need to agree to the
    following
    <a routerLink="../terms-and-conditions">Terms And Conditions</a>, our <a routerLink="../privacy-policy">Privacy
        Policy</a> and be at least 18 years old.
</div>
<form *ngIf="form" [formGroup]="form">
    <div>
        <div>
            <mat-checkbox [required]="true" formControlName="termsAndConditions">Agree with
                Terms And Conditions *
            </mat-checkbox>
            <mat-error
                *ngIf="((form.controls['termsAndConditions'].dirty || formSubmitted) && form.controls['termsAndConditions'].invalid)">
                In order to use the application you must comply with our Terms and Conditions
            </mat-error>
        </div>
        <div>
            <mat-checkbox [required]="true" formControlName="privacyPolicy">Agree with Privacy
                Policy *
            </mat-checkbox>
            <mat-error
                *ngIf="((form.controls['privacyPolicy'].dirty || formSubmitted) && form.controls['privacyPolicy'].invalid)">
                In order to use the application you must comply with our Privacy Policy
            </mat-error>
        </div>
        <div>
            <mat-checkbox [required]="true" formControlName="isAdult">I am 18 years old or
                older *
            </mat-checkbox>
            <mat-hint>
                You must be of legal age for these terms to be legally binding. If you are younger than 18 years old but
                older than 13 your parents or guardians must create an account and agree with these terms and privacy
                policy. If you are younger than 13 years old you can not use our service.
            </mat-hint>
            <mat-error
                *ngIf="((form.controls['isAdult'].dirty || formSubmitted) && form.controls['isAdult'].invalid)">
                In order to use the application you must be at least 18 years old</mat-error>
        </div>
        <div>
            <mat-checkbox [required]="false" formControlName="allowEmail">Agree to receive
                news via email
            </mat-checkbox>
        </div>
        <mat-form-field>
            <mat-label>Public User Name</mat-label>
            <mat-hint>Shown on publicly accessible contents which you create</mat-hint>
            <input formControlName="publicUserName" matInput />
            <mat-error
                *ngIf="((form.controls['publicUserName'].dirty || formSubmitted) && form.controls['publicUserName'].invalid)">
                {{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="center">
        <div class="provider-text">
            Choose one of these providers
        </div>
        <div>
            <button type="button" (click)="submit('google')" class="login-google">
            </button>
        </div>
        <div>
            <button type="button" (click)="submit('facebook')" class="login-facebook">
            </button>
        </div>
    </div>
</form>