<ng-container *ngIf="project">
    <h2>Add Script To Project "{{project.title}}"</h2>
    <form *ngIf="form" [formGroup]="form" (submit)="submit()">
        <div>
            <mat-form-field color="accent" appearance="fill">
                <mat-label>Title</mat-label>
                <input required matInput formControlName="title">
                <mat-error *ngIf="form.controls['title'].invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
            <mat-radio-group aria-label="Script Type" formControlName="type">
                <mat-radio-button [value]="scriptTypesEnum.typescript">TypeScript</mat-radio-button>
                <mat-radio-button [value]="scriptTypesEnum.blockly">Blockly</mat-radio-button>
                <mat-radio-button [value]="scriptTypesEnum.rete">Rete (Experimental)</mat-radio-button>
            </mat-radio-group>
        </div>
        <button *ngIf="!formSubmitted" class="form-submit" mat-button mat-primary-button>Submit</button>
    </form>
</ng-container>