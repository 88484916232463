import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../../config.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

    privacyPolicyHtml;
    privacyPolicyVersion;

    constructor(private readonly configService: ConfigService, private readonly sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.privacyPolicyHtml = this.configService.configSnapshot.privacyPolicyHtml;
        this.sanitizer.bypassSecurityTrustHtml(this.privacyPolicyHtml);
        this.privacyPolicyVersion = this.configService.configSnapshot.privacyPolicyVersion;
    }

}
