<ng-container *ngIf="project">
    <h2>Add Asset to Project "{{project.title}}"</h2>
    <form *ngIf="form" [formGroup]="form" (submit)="submit()">
        <div>
            <mat-form-field color="accent" appearance="fill">
                <mat-label>Title</mat-label>
                <input required matInput formControlName="title">
                <mat-error *ngIf="form.controls['title'].invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
            <label class="file-label">
                <div>File</div>
                <input id="assetFileUpload" type="file" accept=".txt, .csv, .step, .stp, .iges, .igs, .stl, .glb, .gltf, .babylon">
                <mat-progress-bar class="example-margin" [color]="color" [mode]="mode" [value]="uploadPercentage"
                    [bufferValue]="bufferValue">
                </mat-progress-bar>
                <mat-error *ngIf="!fileSizeGood">Your file size of {{mbString(currentAssetFile.size)}} (Mb) is larger than 
                    <ng-container *ngIf="userPlan === userPlanEnum.free">{{config.userPlanFreeMaxSingleAssetsSizeMb}}</ng-container>
                    <ng-container *ngIf="userPlan === userPlanEnum.silver">{{config.userPlanSilverMaxSingleAssetsSizeMb}}</ng-container>
                    <ng-container *ngIf="userPlan === userPlanEnum.gold">{{config.userPlanGoldMaxSingleAssetsSizeMb}}</ng-container>
                    (Mb) allowed for your '{{userPlan}}' plan.
                </mat-error>
                <mat-error *ngIf="!fileNameGood">You file name contains illegal characters. Use letters, spaces, numbers, dots and dashes.</mat-error>
                <mat-error *ngIf="formSubmitted && !currentAssetFile">There are no files selected
                </mat-error>
            </label>
        </div>
        <button class="form-submit" mat-button mat-primary-button>Submit</button>
    </form>
</ng-container>