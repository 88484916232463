<h1>Updated Agreements</h1>
<div class="info">
    Since you last used the application our
    <a *ngIf="termsChanged" routerLink="../terms-and-conditions">Terms And Conditions</a>
    <ng-container *ngIf="termsChanged && privacyPolicyChanged"> and </ng-container><a *ngIf="privacyPolicyChanged"
        routerLink="../privacy-policy">Privacy
        Policy</a> has changed significantly. We therefore ask you to agree with the new rules.
</div>
<form *ngIf="form" [formGroup]="form" (submit)="submit()">
    <div>
        <div *ngIf="termsChanged">
            <mat-checkbox [required]="true" formControlName="termsAndConditions">Agree with latest
                Terms And Conditions *
            </mat-checkbox>
            <mat-error
                *ngIf="((form.controls['termsAndConditions'].dirty || formSubmitted) && form.controls['termsAndConditions'].invalid)">
                In order to use the application you must comply with our Terms and Conditions</mat-error>
        </div>
        <div *ngIf="privacyPolicyChanged">
            <mat-checkbox [required]="true" formControlName="privacyPolicy">Agree with latest Privacy
                Policy *
            </mat-checkbox>
            <mat-error
                *ngIf="((form.controls['privacyPolicy'].dirty || formSubmitted) && form.controls['privacyPolicy'].invalid)">
                In order to use the application you must comply with our Privacy Policy</mat-error>
        </div>
    </div>
    <button class="form-submit" mat-button mat-primary-button>Agree</button>
    <button type="button" class="form-submit" mat-button mat-primary-button (click)="deleteAccount()">I do not
        agree, Delete my Account</button>
</form>