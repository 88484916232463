import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { AnalyticsService } from './analytics.service';
import { AuthService } from './auth/auth.service';
import { CommunicationService } from './shared/communication.service';


enum cookieConsentEnum {
    accepted = 'accepted',
    declined = 'declined',
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    hideTopNav = false;

    constructor(
        private readonly router: Router,
        private readonly auth: AuthService,
        private readonly analytics: AnalyticsService,
        private readonly comms: CommunicationService
    ) {
        const cookieConsent = window.localStorage.getItem('cookieConsent');

        this.auth.isLoggedIn().subscribe();

        if (cookieConsent === cookieConsentEnum.accepted) {
            this.analytics.startTracking();
        } else if (!cookieConsent) {
            const ref = this.comms.openCookieBanner();
            ref.subscribe(res => {
                if (res) {
                    window.localStorage.setItem('cookieConsent', cookieConsentEnum.accepted);
                    this.analytics.startTracking();
                } else {
                    window.localStorage.setItem('cookieConsent', cookieConsentEnum.declined);
                }
            });
        }

        this.router.events.subscribe((ev: NavigationEnd) => {
            if (ev instanceof NavigationEnd) {
                const cookieConsent = window.localStorage.getItem('cookieConsent');
                if (environment.production && cookieConsent === cookieConsentEnum.accepted || !cookieConsent) {
                    this.analytics.trackEvent('navigation', 'navigationEnd', ev.url);
                }
                window.scroll(0, 0);
                this.hideIfInPreview();
            }
        });
    }

    ngOnInit() {
        if (window.location.search.includes('?examples=')) {
            this.router.navigateByUrl('app' + window.location.search);
        }
        this.hideIfInPreview();
    }

    private hideIfInPreview() {
        const split = window.location.pathname.split('/');
        if (split.length > 0) {
            const last = split.pop();
            if (last === 'preview') {
                this.hideTopNav = true;
            }
        }
    }
}
