<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="bit-card">
                <ng-container>
                    <h1>Cookies Policy</h1>
                    <article>
                        <h2>
                            READ THIS DOCUMENT CAREFULLY
                        </h2>
                        <p>
                            We are committed to being transparent about how we and our partners use cookies and similar
                            technologies on our Platform. The purpose of this Cookies Policy is to provide you with
                            information about how cookies and similar technologies work, how we use these technologies,
                            and your choices regarding these technologies.

                            Please read this Cookies Policy together with our <a routerLink="privacy-policy">Privacy
                                Policy</a>, which provides additional
                            information about our use of personal information and your rights. Terms used but not
                            defined in this Cookies Policy can be found in our <a routerLink="privacy-policy">Terms and
                                Conditions</a> .

                            By accessing our Platform, you agree to the use of cookies and similar technologies for the
                            purposes described in this policy.
                        </p>
                        <h2>What are cookies?</h2>
                        <p>
                            Cookies are pieces of data in the form of text files that your browser stores on your hard
                            drive. They allow website providers to identify users, store preferential information, and
                            understand users’ browsing activities. Web beacons (also known as pixel tags and clear GIFs)
                            are electronic images found within website pages that allow website providers to understand
                            users’ browsing activities. Bit by bit developers also uses other similar technologies, such
                            as local
                            storage, for similar purposes described in this Cookies Policy.

                            There are two main types of cookies that can be placed on the Bit by bit developers
                            platform:
                            First-party cookies, which are cookies that are set by Bit by bit developers directly when
                            you use our
                            Platform, and third-party cookies, which are cookies that are set by other companies, such
                            as our partners and services providers.
                        </p>
                        <h2>How Bit by bit developers use cookies?</h2>
                        <p>
                            We use only third party Cookies to track your use of the website via Google Analytics. We
                            use local storage, which is a similar technology to Cookies to store your preferences, like
                            the one to accept or decline Cookies on this website.
                            Local storage allows us to “remember” your preferences when you access our Platform and to
                            customize your experience. Analytics cookies help us understand how our users interact with
                            our Platform, allowing us to improve your experience and to enhance Bit by bit developers
                            Services. We
                            do this by, among other actions, measuring how well our services are functioning and
                            tracking how you navigate through our Platform. As mentioned before we only rely on
                            third-party cookies, like
                            Google Analytics, to collect and analyze information.
                        </p>
                        <h2>
                            Cookies you may find in bitbybit.dev website
                        </h2>
                        <p>
                            Please do not confuse cookies that are used on this website and those used in our Teachable
                            based School at <a href="https://school.bitbybit.dev">https://school.bitbybit.dev</a>
                            website. To
                            understand those cookies, please refer to <a
                                href="https://teachable.com/cookies-policy">Cookies Policy of Teachable</a>. If you
                            stay in bitbybit.dev site you will be able to accept or decline Google Analytics tracking.
                            No fundamental features will be lost due to your choice to decline those cookies.
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Cookie name</th>
                                    <th>Default expiration time</th>
                                    <th>Description</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td><code translate="no" dir="ltr">_<wbr>ga</code></td>
                                    <td>2 years</td>
                                    <td>Used to distinguish users.</td>
                                </tr>
                                <tr>
                                    <td><code translate="no" dir="ltr">_<wbr>gid</code></td>
                                    <td>24 hours</td>
                                    <td>Used to distinguish users.</td>
                                </tr>
                                <tr>
                                    <td><code translate="no" dir="ltr">_<wbr>ga_<wbr>&lt;container-id&gt;</code></td>
                                    <td>2 years</td>
                                    <td>Used to persist session state.</td>
                                </tr>
                            </tbody>
                        </table>
                        <h2>
                            What choices do you have regarding cookies?
                        </h2>
                        Most web browsers are set to accept cookies by default. If you prefer, you can usually set your
                        browser to remove or reject cookies by following the instructions in your browser settings. Some
                        analytics providers we partner with, like Google Analytics, may also provide you with additional
                        opt-out mechanisms.

                        Note that deleting cookies or directing your browser to refuse them should not limit your use of
                        our Platform. This might change in the future though, so be sure to check this Cookies Policy
                        regularly.

                        Contact Us
                        If you have questions regarding this Cookies Policy, please email us at: <a href="mailto:info@bitbybit.dev">info@bitbybit.dev</a>.
                    </article>
                </ng-container>
            </mat-card>
        </div>
    </div>
</div>