<ng-container *ngIf="visibility && projects && projects.length > 0">
    <mat-grid-list class="d-none d-lg-flex" cols="4" rowHeight="1:1.5">
        <ng-container *ngFor="let project of projects">
            <mat-grid-tile>
                <mat-grid-tile-header>
                    <a *ngIf="!inModal" class="project-title"
                        [routerLink]="'/projects/' + getRouteFromVisibility(visibility) + '/' + project.id">
                        {{project.title}}
                    </a>
                    <a *ngIf="inModal" class="project-title" (click)="projectOpened($event, project)">
                        {{project.title}}
                    </a>
                </mat-grid-tile-header>
                <a *ngIf="!inModal" [routerLink]="'/projects/' + getRouteFromVisibility(visibility) +'/' + project.id">
                    <img class="project-image" alt="Project Image"
                        [ngClass]="{picturePlaceholder: project.image.mid ? false : true}"
                        [src]="project.image.mid ? project.image.mid : 'assets/image-placeholder.png'" />
                </a>
                <a *ngIf="inModal" (click)="projectOpened($event, project)">
                    <img class="project-image" alt="Project Image"
                        [ngClass]="{picturePlaceholder: project.image.mid ? false : true}"
                        [src]="project.image.mid ? project.image.mid : 'assets/image-placeholder.png'" />
                </a>
                <mat-grid-tile-footer *ngIf="!authorId">
                    by &nbsp;
                    <a *ngIf="!inModal"
                        [routerLink]="'/projects/' + getRouteFromVisibility(visibility) + '/author/' + project.authorPublicUserName">
                        {{project.authorPublicUserName}}
                    </a>
                    <a class="author-name" *ngIf="inModal" (click)="authorOpened($event, project.authorPublicUserName, project.authorId)">
                        {{project.authorPublicUserName}}
                    </a>
                </mat-grid-tile-footer>
            </mat-grid-tile>
        </ng-container>
    </mat-grid-list>
    <mat-grid-list class="d-lg-none" cols="2" rowHeight="1:1.5">
        <ng-container *ngFor="let project of projects">
            <mat-grid-tile>
                <mat-grid-tile-header>
                    <a *ngIf="!inModal" class="project-title"
                        [routerLink]="'/projects/' + getRouteFromVisibility(visibility) + '/' + project.id">
                        {{project.title}}
                    </a>
                    <a *ngIf="inModal" class="project-title" (click)="projectOpened($event, project)">
                        {{project.title}}
                    </a>
                </mat-grid-tile-header>
                <a *ngIf="!inModal" [routerLink]="'/projects/' + getRouteFromVisibility(visibility) + '/' + project.id">
                    <img class="project-image" alt="Project Image"
                        [ngClass]="{picturePlaceholder: project.image.mid ? false : true}"
                        [src]="project.image.mid ? project.image.mid : 'assets/image-placeholder.png'" />
                </a>
                <a *ngIf="inModal" (click)="projectOpened($event, project)">
                    <img class="project-image" alt="Project Image"
                        [ngClass]="{picturePlaceholder: project.image.mid ? false : true}"
                        [src]="project.image.mid ? project.image.mid : 'assets/image-placeholder.png'" />
                </a>
                <mat-grid-tile-footer *ngIf="!authorId">
                    by &nbsp;
                    <a *ngIf="!inModal" 
                        [routerLink]="'/projects/' + getRouteFromVisibility(visibility) + '/author/' + project.authorPublicUserName">
                        {{project.authorPublicUserName}}
                    </a>
                    <a class="author-name" *ngIf="inModal" (click)="authorOpened($event, project.authorPublicUserName, project.authorId)">
                        {{project.authorPublicUserName}}
                    </a>
                </mat-grid-tile-footer>
            </mat-grid-tile>
        </ng-container>
    </mat-grid-list>
    <div class="row">
        <div class="col">
            <button class="float-right" *ngIf="projects && projects.length > 0 && !(maxPage === currentPage)"
                (click)="nextPage()" mat-button mat-icon-button>
                <mat-icon>arrow_right</mat-icon>
            </button>
            <button class="float-right" *ngIf="projects && projects.length > 0 && currentPage != 1"
                (click)="previousPage()" mat-button mat-icon-button>
                <mat-icon>arrow_left</mat-icon>
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!projects || projects.length === 0">
    There are no projects in your search results.
</ng-container>