import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfigService } from '../../config.service';
import { Constants } from '../../constants';
import { DatabaseService } from '../../database/database.service';
import { userPlanEnum } from '../../database/models/user-plan.enum';
import { userStateEnum } from '../../database/models/user-state.enum';
import { CommunicationService } from '../../shared/communication.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

    form = new FormGroup({
        publicUserName: new FormControl('', [Validators.required, Validators.minLength(Constants.userNameMinLength), Validators.maxLength(Constants.userNameMaxLength), Validators.pattern('^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$')], [this.usernameValidator()]),
        termsAndConditions: new FormControl(false, [Validators.requiredTrue]),
        privacyPolicy: new FormControl(false, [Validators.requiredTrue]),
        isAdult: new FormControl(false, [Validators.requiredTrue]),
        allowEmail: new FormControl(false),
    });

    fileExtensionsConsistent = true;
    formSubmitted = false;

    constructor(
        private readonly dbService: DatabaseService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly auth: AuthService,
        private readonly configService: ConfigService,
        private readonly comms: CommunicationService
    ) { }

    ngOnInit(): void {
    }

    submit(login: string): void {
        if (this.form.valid) {
            this.formSubmitted = false;
            this.comms.openSpinnerSnackBar(`Logging in.`);
            if (login === 'google') {
                this.auth.doGoogleLogin().pipe(take(1)).subscribe(s => {
                    this.createUserDb();
                }, err => {
                    this.comms.openSnackBar(`Something went wrong. Try again later. Error message. ${err.message}`, 'Close', true);
                });
            } else if (login === 'facebook') {
                this.auth.doFacebookLogin().pipe(take(1)).subscribe(s => {
                    this.createUserDb();
                }, err => {
                    this.comms.openSnackBar(`Something went wrong. Try again later. Error message. ${err.message}`, 'Close', true);
                });
            }
        } else {
            this.formSubmitted = true;
        }
    }

    usernameValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return this.dbService.users.publicUserNameExists(control.value).pipe(
                map(res => {
                    // if res is true, username exists, return true
                    return res ? { usernameExists: true } : null;
                    // NB: Return null if there is no error
                })
            );
        };
    }

    private createUserDb() {
        this.comms.openSpinnerSnackBar(`Saving agreements.`);
        this.dbService.users.createUserTransaction({
            uid: this.auth.userSnapshot.uid,
            publicUserName: this.form.controls.publicUserName.value,
            profileImage: {
                min: '',
                mid: '',
                max: '',
            }
        }, {
            uid: this.auth.userSnapshot.uid,
            isAdult: this.form.controls.isAdult.value,
            date: this.dbService.serverTimestamp(),
            lastLoginDate: this.dbService.serverTimestamp(),
            lastEditedDate: this.dbService.serverTimestamp(),
            agreedToTermsAndConditionsDate: this.dbService.serverTimestamp(),
            agreedToTermsAndConditionsVersion: this.configService.configSnapshot.termsAndConditionsVersion,
            agreedToPrivacyPolicyDate: this.dbService.serverTimestamp(),
            agreedToPrivacyPolicyVersion: this.configService.configSnapshot.privacyPolicyVersion,
            userPlan: userPlanEnum.free,
            userPlanActivatedDate: this.dbService.serverTimestamp(),
            userPlanExpiresInDays: 365 * 100,
            agreedToReceiveEmails: this.form.controls.allowEmail.value,
            state: userStateEnum.active
        }).subscribe({
            next: p => {
                setTimeout(() => {
                    this.auth.isLoggedIn().subscribe(() => {
                        this.comms.openSnackBar(`You are now signed up`, 'Close', false, Constants.snackbarTimer);
                        this.router.navigate(['../../projects/my'], {
                            relativeTo: this.route,
                            queryParams: { reload: 'true' }
                        });
                    });
                }, 4000);
            }, error: err => {
                this.comms.openSnackBar(`Something went wrong, you may already have an account. Try logging in. Otherwise try signing up again later. Error message: ${err.message}`, 'Close', true);
            }
        });
    }

    getErrorMessage(): string {
        let result = '';
        let control = this.form.controls.publicUserName;
        if (control.hasError('required')) {
            result = 'You must enter a value';
        } else if (control.hasError('maxlength')) {
            result = `Field contains more than ${Constants.userNameMaxLength}  characters`;
        } else if (control.hasError('minlength')) {
            result = `Field contains less than ${Constants.userNameMinLength}  characters`;
        } else if (control.hasError('taken')) {
            result = `This username is already taken, please consider another one`;
        } else if (control.hasError('pattern')) {
            result = `Can contain english alphabet letters, digits, spaces, dashes and underscores. Must begin and end with letters or digits.`;
        } else if (control.hasError('usernameExists')) {
            result = `Entered public user name is already taken by someone else. Try different one.`;
        }
        return result;
    }
}
