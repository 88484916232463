<ng-container>
    <ng-container *ngIf="firstTime">
        <h2>Welcome, {{publicUserName}}!</h2>
        <app-quota-breach *ngIf="!allowedToCreate()" [stats]="stats" [config]="config" [currentUser]="currentUser">
        </app-quota-breach>
        <p>
            We are happy you managed to sign up. Let's get started by creating your first project and adding a script to
            it. You will need to provide a title and a description.
        </p>
    </ng-container>
    <ng-container *ngIf="!firstTime">
        <h2>My Projects</h2>
        <h3 *ngIf="stats && stats.totalCount !== undefined">Total {{stats.totalCount}} Project(s)
            <ng-container *ngIf="stats.totalAssetsSizeMb && stats.totalAssetsSizeMb > 0 && assetPercentage <= 80"> /
                Total Assets
                {{stats.totalAssetsSizeMb.toFixed(3)}} MB
            </ng-container>
        </h3>
        <ng-container *ngIf="assetPercentage > 80">
            <h4>Assets reached {{stats.totalAssetsSizeMb.toFixed(3)}} MB out of {{assetMax.toFixed(3)}} MB allowed</h4>
            <mat-progress-bar *ngIf="stats.totalAssetsSizeMb && stats.totalAssetsSizeMb > 0" color="primary"
                mode="determinate" [value]="assetPercentage" [bufferValue]="assetMax">
            </mat-progress-bar>
            <h5 *ngIf="assetPercentage >= 100">You will no longer be able to upload new assets. Consider deleting old
                assets or <a routerLink="/auth/pick-plan">purchase another plan</a>.</h5>
        </ng-container>
        <app-quota-breach *ngIf="currentUser && !allowedToCreate()" [stats]="stats" [config]="config"
            [currentUser]="currentUser">
        </app-quota-breach>
        <p>This is an overview of all your projects. You can manage all of your typescript, blockly and rete scripts
            here.</p>
    </ng-container>
    <ng-container *ngIf="!firstTime && (!projects || projects.length === 0)">
        <div>
            You currently don't have any projects
        </div>
    </ng-container>
    <ng-container *ngIf="projects && projects.length > 0 && stats.privateCount !== undefined">
        <mat-accordion>
            <ng-container *ngFor="let project of projects">
                <mat-expansion-panel id="project-{{project.id}}" (opened)="panelOpened(project)"
                    (closed)="panelClosed(project)">
                    <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="120px">
                        <mat-panel-title>
                            <div class="row">
                                <div class="d-none d-sm-block col-12 col-sm-4 col-lg-2">
                                    <img class="header-image"
                                        [ngClass]="{picturePlaceholder: project.image.min ? false : true}"
                                        [src]="project.image.min ? project.image.min : 'assets/image-placeholder.png'" />
                                </div>
                                <div class="col-12 col-sm-8 col-lg-10 vis-title">
                                    <mat-icon matTooltip="Private"
                                        *ngIf="project.visibility === projectVisibilityEnum.private">
                                        person
                                    </mat-icon>
                                    <mat-icon matTooltip="Visible To Silver Community"
                                        *ngIf="project.visibility === projectVisibilityEnum.silverCommunity">
                                        group
                                    </mat-icon>
                                    <mat-icon matTooltip="Visible To Gold Community"
                                        *ngIf="project.visibility === projectVisibilityEnum.goldCommunity">
                                        group
                                    </mat-icon>
                                    <mat-icon matTooltip="Visible To Everyone"
                                        *ngIf="project.visibility === projectVisibilityEnum.public">
                                        groups
                                    </mat-icon>
                                    <div class="title">
                                        {{project.title}}
                                    </div>
                                </div>
                            </div>
                        </mat-panel-title>
                        <span class="actions">
                            <button mat-mini-fab aria-label="Edit project" (click)="projectEdit(project.id, $event)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-mini-fab aria-label="Delete project" (click)="projectDelete(project, $event)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <img class="project-image"
                                [ngClass]="{picturePlaceholder: project.image.mid ? false : true}"
                                [src]="project.image.mid ? project.image.mid : 'assets/image-placeholder.png'" />
                            <button mat-button mat-icon-button (click)="imageAdd(project)">
                                <mat-icon>photo_camera</mat-icon>
                            </button>
                            <button mat-button mat-icon-button (click)="imageDelete(project)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div class="col-12 col-md-7">
                            <dl class="descriptions">
                                <div>
                                    <dt>Author</dt>
                                    <dd>{{project.authorPublicUserName}}</dd>
                                </div>
                                <div>
                                    <dt>Description</dt>
                                    <dd>{{project.description}}</dd>
                                </div>
                                <div>
                                    <dt>Visibility</dt>
                                    <dd>{{project.visibility}}</dd>
                                </div>
                                <div>
                                    <dt>Date Created</dt>
                                    <dd *ngIf="project.date">{{project.date.toDate() | date:'medium'}}</dd>
                                </div>
                                <div>
                                    <dt>Last Edit Date</dt>
                                    <dd *ngIf="project.lastEditedDate">{{project.lastEditedDate.toDate() |
                                        date:'medium'}}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <br>
                    <mat-card class="category">
                        <mat-card-title>
                            <h4>Scripts | <app-script-quota *ngIf="project.stats" [stats]="project.stats"
                                    [config]="config" [currentUser]="currentUser"></app-script-quota>
                            </h4>
                            <span class="actions">
                                <button *ngIf="project.stats && allowedToCreateScript(project)" matTooltip="Add script"
                                    mat-mini-fab aria-label="Add new script" (click)="scriptAdd(project.id)">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <button mat-mini-fab aria-label="Refresh scripts" matTooltip="Refresh scripts"
                                    (click)="refreshScripts(project)">
                                    <mat-icon>refresh</mat-icon>
                                </button>
                            </span>
                        </mat-card-title>
                        <ng-container *ngIf="project.scripts && project.scripts.length > 0">
                            <ng-container *ngFor="let ts of project.scripts">
                                <mat-card>
                                    <mat-card-title>
                                        <h5>{{ts.title}}</h5>
                                        <span class="actions">
                                            <button mat-mini-fab aria-label="Launch script" (click)="scriptLaunch(ts)">
                                                <mat-icon>launch</mat-icon>
                                            </button>
                                            <button mat-mini-fab aria-label="Edit script"
                                                (click)="scriptEdit(project.id, ts.id)">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            <button mat-mini-fab aria-label="Delete script"
                                                (click)="scriptDelete(ts, project)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </span>
                                    </mat-card-title>
                                    <mat-card-subtitle>
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                                <img class="script-image"
                                                    [ngClass]="{picturePlaceholder: ts.image.min ? false : true}"
                                                    [src]="ts.image.min ? ts.image.min : 'assets/image-placeholder.png'" />
                                                <button mat-button mat-icon-button (click)="scriptImageAdd(ts)">
                                                    <mat-icon>photo_camera</mat-icon>
                                                </button>
                                                <button mat-button mat-icon-button (click)="scriptImageDelete(ts)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-8 col-lg-9">
                                                <dl class="descriptions">
                                                    <div>
                                                        <dt>Type</dt>
                                                        <dd>
                                                            <img *ngIf="ts.type==='typescript'" class="script-type"
                                                                src="assets/typescript_logo_mini.png" />
                                                            <img *ngIf="ts.type==='blockly'" class="script-type"
                                                                src="assets/blockly_logo_mini.png" />
                                                            <img *ngIf="ts.type==='rete'" class="script-type"
                                                                src="assets/rete_logo_mini.png" />
                                                            <div>
                                                                {{ts.type}}
                                                            </div>
                                                        </dd>
                                                    </div>
                                                    <div>
                                                        <dt>Date Created</dt>
                                                        <dd>{{ts.date.toDate() | date:'medium'}}</dd>
                                                    </div>
                                                    <div>
                                                        <dt>Last Edit Date</dt>
                                                        <dd>{{ts.lastEditedDate.toDate() | date:'medium'}}</dd>
                                                    </div>
                                                </dl>
                                            </div>
                                        </div>
                                    </mat-card-subtitle>
                                </mat-card>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="project.scripts === undefined">
                            <mat-spinner matTooltip="Scripts are being loaded" color="accent" diameter="30">
                            </mat-spinner>
                            Loading scripts
                        </ng-container>
                        <ng-container *ngIf="project.scripts !== undefined && project.scripts.length === 0">
                            <span class="empty-list">
                                This project does not have any scripts yet. Click + button to
                                add a new
                                empty
                                script to this
                                project.
                            </span>
                        </ng-container>
                    </mat-card>
                    <br>
                    <mat-card class="category">
                        <mat-card-title>
                            <h4>Assets | <app-asset-quota *ngIf="project.stats" [stats]="project.stats"
                                    [config]="config" [currentUser]="currentUser"></app-asset-quota>
                            </h4>
                            <span class="actions">
                                <button *ngIf="project.stats && allowedToCreateAsset(project) && assetPercentage < 100"
                                    matTooltip="Add asset" mat-mini-fab aria-label="Add new asset"
                                    (click)="assetAdd(project.id)">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <button mat-mini-fab aria-label="Refresh assets" matTooltip="Refresh assets"
                                    (click)="refreshAssets(project)">
                                    <mat-icon>refresh</mat-icon>
                                </button>
                            </span>
                        </mat-card-title>
                        <ng-container *ngIf="project.assets && project.assets.length > 0">
                            <ng-container *ngFor="let asset of project.assets">
                                <mat-card>
                                    <mat-card-title>
                                        <h5>{{asset.title}}</h5>
                                        <span class="actions">
                                            <button mat-mini-fab aria-label="Delete the asset"
                                                (click)="assetDelete(asset, project)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </span>
                                    </mat-card-title>
                                    <mat-card-subtitle>
                                        <dl class="descriptions">
                                            <div>
                                                <dt>File Name</dt>
                                                <dd><a download="file" [href]="asset.downloadUrl">{{asset.fileName}}</a>
                                                </dd>
                                            </div>
                                            <div>
                                                <dt>Extension</dt>
                                                <dd>{{asset.extension}}</dd>
                                            </div>
                                            <div>
                                                <dt>Size</dt>
                                                <dd>{{asset.size | fileSize}}</dd>
                                            </div>
                                            <div>
                                                <dt>Date Created</dt>
                                                <dd>{{asset.date.toDate() | date:'medium'}}</dd>
                                            </div>
                                            <div>
                                                <dt>Last Edit Date</dt>
                                                <dd>{{asset.lastEditedDate.toDate() | date:'medium'}}</dd>
                                            </div>
                                        </dl>
                                    </mat-card-subtitle>
                                </mat-card>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!project.assets || project.assets.length === 0">
                            <span class="empty-list">
                                This project does not have any assets yet. Click + button to upload a
                                new asset to
                                this
                                project. You can use assets in your scripts via our Assets API.
                            </span>
                        </ng-container>
                    </mat-card>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
        <br>
    </ng-container>
    <div class="row">
        <div class="col-6">
            <button *ngIf="allowedToCreate()" mat-button mat-raised-button (click)="projectNew()">New Project</button>
        </div>
        <div class="col-6">
            <button class="float-right" *ngIf="projects && projects.length > 0 && !(maxPage === currentPage)"
                (click)="nextPage()" mat-button mat-icon-button>
                <mat-icon>arrow_right</mat-icon>
            </button>
            <button class="float-right" *ngIf="projects && projects.length > 0 && currentPage != 1"
                (click)="previousPage()" mat-button mat-icon-button>
                <mat-icon>arrow_left</mat-icon>
            </button>
        </div>
    </div>

</ng-container>