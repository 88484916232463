<h1>Agreements</h1>
<div class="info">
    It is the first time this account has accessed our application and needs to agree to the
    following
    <a routerLink="../terms-and-conditions">Terms And Conditions</a> and our <a routerLink="../privacy-policy">Privacy
        Policy</a>.
</div>
<form *ngIf="form" [formGroup]="form" (submit)="submit()">
    <div>
        <div>
            <mat-checkbox [required]="true" formControlName="termsAndConditions">Agree with
                Terms And Conditions *
            </mat-checkbox>
            <mat-error
                *ngIf="((form.controls['termsAndConditions'].dirty || formSubmitted) && form.controls['termsAndConditions'].invalid)">
                In order to use the application you must comply with our Terms and Conditions</mat-error>
        </div>
        <div>
            <mat-checkbox [required]="true" formControlName="privacyPolicy">Agree with Privacy
                Policy *
            </mat-checkbox>
            <mat-error
                *ngIf="((form.controls['privacyPolicy'].dirty || formSubmitted) && form.controls['privacyPolicy'].invalid)">
                In order to use the application you must comply with our Privacy Policy</mat-error>
        </div>
        <div>
            <mat-checkbox [required]="true" formControlName="isAdult">I am 18 years old or older *
            </mat-checkbox>
            <mat-hint>
                You must be of legal age for these terms to be legally binding. If you are younger than 18 years old but
                older than 13 your parents or guardians must create an account and agree with these terms and privacy
                policy. If you are younger than 13 years old you can not use our service.
            </mat-hint>
            <mat-error
                *ngIf="((form.controls['isAdult'].dirty || formSubmitted) && form.controls['isAdult'].invalid)">
                In order to use the application you must be at least 18 years old</mat-error>
        </div>
        <div>
            <mat-checkbox [required]="false" formControlName="allowEmail">Agree to receive
                news via email
            </mat-checkbox>
        </div>
        <mat-form-field>
            <mat-label>Public User Name</mat-label>
            <mat-hint>Shown on publicly accessible content which you create. Choose wisely, you can not change it later.
            </mat-hint>
            <input formControlName="publicUserName" matInput />
            <mat-error
                *ngIf="((form.controls['publicUserName'].dirty || formSubmitted) && form.controls['publicUserName'].invalid)">
                {{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <button class="form-submit" mat-button mat-primary-button>Agree</button>
    <button type="button" class="form-submit" mat-button mat-primary-button (click)="deleteAccount()">I do not
        agree, Delete my Account</button>
</form>