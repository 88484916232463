<span>
    <ng-container *ngIf="currentUser.userPlan === userPlanEnum.free">
        <a routerLink="/auth/pick-plan" class="quota-breach" *ngIf="stats.totalScripts >= config.userPlanFreeMaxScriptsInProject">
            {{currentUser.userPlan.toUpperCase()}} plan limit - {{stats.totalScripts}} out of
            {{config.userPlanFreeMaxScriptsInProject}}.
        </a>
        <ng-container *ngIf="stats.totalScripts < config.userPlanFreeMaxScriptsInProject">
            {{stats.totalScripts}} out of {{config.userPlanFreeMaxScriptsInProject}} allowed.
        </ng-container>
    </ng-container>
    <ng-container *ngIf="currentUser.userPlan === userPlanEnum.silver">
        <a routerLink="/auth/pick-plan" class="quota-breach" *ngIf="stats.totalScripts >= config.userPlanSilverMaxScriptsInProject">
            {{currentUser.userPlan.toUpperCase()}} plan limit - {{stats.totalScripts}} out of
            {{config.userPlanSilverMaxScriptsInProject}}.
        </a>
        <ng-container *ngIf="stats.totalScripts < config.userPlanSilverMaxScriptsInProject">
            {{stats.totalScripts}} out of {{config.userPlanSilverMaxScriptsInProject}} allowed.
        </ng-container>
    </ng-container>
    <ng-container *ngIf="currentUser.userPlan === userPlanEnum.gold">
        <a routerLink="/auth/pick-plan" class="quota-breach" *ngIf="stats.totalScripts >= config.userPlanGoldMaxScriptsInProject">
            {{currentUser.userPlan.toUpperCase()}} plan limit - {{stats.totalScripts}} out of
            {{config.userPlanGoldMaxScriptsInProject}}.
        </a>
        <ng-container *ngIf="stats.totalScripts < config.userPlanGoldMaxScriptsInProject">
            Total {{stats.totalScripts}}.
        </ng-container>
    </ng-container>
</span>