import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from '@babylonjs/core';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthResolver implements Resolve<any> {
  constructor(private readonly auth: AuthService) {}

  resolve(
  ): Observable<any>|Promise<any>|any {
    return this.auth.isLoggedIn();
  }
}
