import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../../constants';
import { CommunicationService } from '../../shared/communication.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    subscription;

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly comms: CommunicationService
    ) { }

    ngOnInit() {

    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    loginGoogle() {
        this.subscription = this.authService.doGoogleLogin().subscribe(s => {
            this.comms.openSnackBar(`Login successful`, 'Close', false, Constants.snackbarTimer);
            this.router.navigate(['../../projects/my'], { relativeTo: this.route })
        }, err => {
            this.comms.openSnackBar(`Something went wrong. Try again later. Error message. ${err.message}`, 'Close', true);
        });
    }

    loginFacebook() {
        this.subscription = this.authService.doFacebookLogin().subscribe(s => {
            this.comms.openSnackBar(`Login successful`, 'Close', false, Constants.snackbarTimer);
            this.router.navigate(['../../projects/my'], { relativeTo: this.route })
        }, err => {
            this.comms.openSnackBar(`Something went wrong. Try again later. Error message. ${err.message}`, 'Close', true);
        });
    }
}
