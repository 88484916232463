<div class="center">
    <h1>Login</h1>
    <div class="provider-text">
        Choose one of these providers
    </div>
    <div>
        <button (click)="loginGoogle()" class="login-google">
        </button>
    </div>
    <div>
        <button (click)="loginFacebook()" class="login-facebook">
        </button>
    </div>
</div>