<nav class="fixed-top">
    <div [ngClass]="{dashboard: !isInApp, appDashboard: isInApp}">
        <ng-container>
            <div>
                <div class="row">
                    <div class="col-8 logo-with-projects">
                        <mat-spinner diameter="40" *ngIf="isLoggedOut === undefined">
                        </mat-spinner>
                        <a *ngIf="(isLoggedOut || user)" routerLink="../" class="logo">
                            <img src="assets/logo-gold-small.png" />
                        </a>
                        <span *ngIf="user">
                            <mat-menu #projectsMenu="matMenu">
                                <a routerLink="projects/my" mat-menu-item>
                                    <mat-icon>person</mat-icon>My
                                </a>
                                <a routerLink="projects/silver"
                                    *ngIf="userDb && (userDb.userPlan === userPlanEnum.silver || userDb.userPlan === userPlanEnum.gold)"
                                    mat-menu-item>
                                    <mat-icon>group</mat-icon>Silver Community
                                </a>
                                <a routerLink="projects/gold" *ngIf="userDb && userDb.userPlan === userPlanEnum.gold"
                                    mat-menu-item>
                                    <mat-icon>group</mat-icon>Gold Community
                                </a>
                                <a routerLink="projects/public" mat-menu-item>
                                    <mat-icon>groups</mat-icon>Public
                                </a>
                            </mat-menu>
                            <a mat-button *ngIf="!isInApp" [matMenuTriggerFor]="projectsMenu">
                                <span>Projects</span>
                            </a>
                            <mat-menu #projectsAppMenu="matMenu">
                                <a (click)="openMyProjectsDialog($event)" mat-menu-item>All Projects
                                </a>
                                <a routerLink="projects/my" mat-menu-item>
                                    Edit My Projects
                                </a>
                            </mat-menu>
                            <a mat-button *ngIf="isInApp" [matMenuTriggerFor]="projectsAppMenu"
                                class="d-none d-sm-inline-block">
                                <span>Projects</span>
                            </a>
                            <span>
                                <app-navigator-breadcrumb *ngIf="isInApp" [user]="user"></app-navigator-breadcrumb>
                            </span>
                        </span>
                        <ng-container *ngIf="!isInApp && isLoggedOut !== undefined && !user">
                            <a routerLink="projects/public" mat-button>
                                Projects
                            </a>
                        </ng-container>
                        <ng-container *ngIf="isInApp && isLoggedOut !== undefined && !user">
                            <button (click)="openMyProjectsDialog($event)" mat-button>
                                Projects
                            </button>
                        </ng-container>
                        <ng-container *ngIf="isInApp && isLoggedOut !== undefined && !user">
                            <app-navigator-breadcrumb *ngIf="isInApp"></app-navigator-breadcrumb>
                        </ng-container>
                    </div>
                    <div class="col-4">
                        <div *ngIf="user" class="profile">
                            <mat-menu #appMenu="matMenu">
                                <a routerLink="/auth/profile" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Import">account_circle
                                    </mat-icon>
                                    Profile
                                </a>
                                <a routerLink="/auth/pick-plan" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Import">table_chart
                                    </mat-icon>
                                    Plans
                                </a>
                                <button (click)="logout()" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Import">logout
                                    </mat-icon>
                                    Logout
                                </button>
                            </mat-menu>
                            <button class="user" [matMenuTriggerFor]="appMenu">
                                <span class="d-none d-lg-inline">{{publicUserName}} </span>
                                <span *ngIf="userPlan" class="d-none d-lg-inline"> | {{userPlan}}</span>
                                <span *ngIf="!userPlan" matTooltip="" class="d-none d-lg-inline"> | Plan activation
                                    requires Logout and Login</span>
                                <img class="profile-image" [src]="user.photoURL" alt="Profile pic" />
                            </button>
                        </div>
                        <span class="profile login" *ngIf="isLoggedOut">
                            <a mat-button *ngIf="isLoggedOut" routerLink="auth/login">Login</a>
                            <a mat-button *ngIf="isLoggedOut" class="d-none d-md-inline-block" routerLink="auth/sign-up">Sign Up</a>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</nav>