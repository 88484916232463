<!-- <button mat-button>
My
</button> -->
    <ng-container *ngIf="publicUserName">
        <span class="d-none d-md-inline-block">/</span>
        <button class="d-none d-md-inline-block" mat-button (click)="authorProjects()">
            <span class="title" [matTooltip]="'Author: ' + publicUserName">
                {{publicUserName}}
            </span>
        </button>
        <span>/</span>
    </ng-container>
    <ng-container *ngIf="project" >
        <button mat-button (click)="projectScripts()">
            <span class="title" [matTooltip]="'Project: ' + project.title">
                {{project.title}}
            </span>
        </button>
        <span class="d-none d-md-inline-block">/</span>
    </ng-container>
    <ng-container *ngIf="script" >
        <button class="d-none d-md-inline-block" mat-button (click)="scriptDetails()">
            <span class="title" [matTooltip]="'Script: ' + script.title">
                {{script.title}}
            </span>
        </button>
    </ng-container>