import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { Constants } from '../../../constants';
import { DatabaseService } from '../../../database/database.service';
import { Project } from '../../../database/models/project';
import { Script } from '../../../database/models/script';
import { CommunicationService } from '../../../shared/communication.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
    selector: 'app-script-image-add',
    templateUrl: './script-image-add.component.html',
    styleUrls: ['./script-image-add.component.scss']
})
export class ScriptImageAddComponent implements OnInit {

    projectIdParam = 'projectId';
    projectId: string;
    project: Project;
    scriptIdParam = 'scriptId';
    scriptId: string;
    script: Script;
    color: ThemePalette = 'primary';
    mode: ProgressBarMode = 'determinate';

    bufferValue = 100;

    resolutions = [
        { width: 320, height: 320 },
        { width: 640, height: 640 },
        { width: 960, height: 960 },
    ]

    filePercentages: {
        name: string,
        percentage: number
    }[] = [
            { name: '', percentage: 0 },
            { name: '', percentage: 0 },
            { name: '', percentage: 0 }];

    uploadStarted = false;

    constructor(
        private readonly comms: CommunicationService,
        private readonly auth: AuthService,
        private readonly route: ActivatedRoute,
        private readonly databaseService: DatabaseService,
        private readonly router: Router,
        private readonly storage: AngularFireStorage
    ) { }

    ngOnInit() {
        this.projectId = this.route.snapshot.params[this.projectIdParam];
        this.scriptId = this.route.snapshot.params[this.scriptIdParam]

        this.databaseService.projects.getByIdFb(this.projectId).subscribe((p) => {
            this.project = p;
        });
        this.databaseService.script.getScript(this.scriptId, this.projectId).subscribe((p) => {
            this.script = p;
        });
    }

    imagesMade(files: File[]) {

        this.comms.openSpinnerSnackBar(`Uploading optimized images`);
        this.uploadStarted = true;

        const uploadTasks = files.map(file => {
            const path = `users/${this.auth.userSnapshot.uid}/projects/${this.projectId}/scripts/${this.scriptId}/photos/${file.name}`;
            return this.storage.upload(
                path,
                file,
                {
                    cacheControl: 'public,max-age=2592000',
                    customMetadata: {
                        'visibility': this.project.visibility,
                        'uid': this.auth.userSnapshot.uid,
                        'authorPublicUserName': this.auth.userDb.publicUserName,
                        'projectId': this.projectId,
                        'scriptId': this.scriptId,
                        'fileType': 'scriptPhoto',
                        'title': 'Main script photo',
                    }
                }
            )
        });

        uploadTasks.forEach((uploadTask, index) => {
            this.filePercentages[index].name = files[index].name;
            uploadTask.percentageChanges().subscribe(percentage => {
                this.filePercentages[index].percentage = percentage;
            });
        });
        Promise.all(uploadTasks).then(snapshots => {
            const downloadUrls: Observable<string>[] = files.map(file => {
                const path = `users/${this.auth.userSnapshot.uid}/projects/${this.projectId}/scripts/${this.scriptId}/photos/${file.name}`;
                return this.storage.ref(path).getDownloadURL();
            });

            forkJoin(downloadUrls).subscribe(s => {
                const image = {
                    min: s[0],
                    mid: s[1],
                    max: s[2],
                };
                this.databaseService.script.update({
                    image,
                    lastEditedDate: this.databaseService.serverTimestamp()
                }, this.scriptId, this.projectId).subscribe(res => {
                    this.comms.openSnackBar(`Successfully uploaded photos for script '${this.script.title}'.`, 'Close', false, Constants.snackbarTimer);
                    this.router.navigate(['/projects/my']);
                },
                    err => {
                        this.uploadStarted = false;
                        this.comms.openSnackBar(`Error happened when uploading photos for script '${this.script.title}'. Try again later. Error message: ${err.message}.`, 'Close', true);
                    });
            });
        }, err => {
            this.uploadStarted = false;
            this.comms.openSnackBar(`Something went wrong when uploading photos. Try again later. Error message ${err.message}.`, 'Close', true);
        });
    }

}
