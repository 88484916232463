<div class="container">
    <div class="row">
        <div class="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-6 col-md-8 mx-auto">
                    <mat-card>
                        <router-outlet></router-outlet>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>