import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { projectVisibilityEnum } from 'functions/src/interfaces/project-visibility.enum';
import { AuthService } from '../../auth/auth.service';
@Component({
    selector: 'app-public-projects-gallery',
    templateUrl: './public-projects-gallery.component.html',
    styleUrls: ['./public-projects-gallery.component.scss']
})
export class PublicProjectsGalleryComponent implements OnInit {
    
    visibility: projectVisibilityEnum;
    projectVisibilityEnum = projectVisibilityEnum;
    loggedIn: boolean;
    constructor(
        private readonly auth: AuthService,
        private readonly route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.loggedIn = this.auth.userDb !== undefined;
        this.visibility = this.route.snapshot.data.visibility;
    }

}
