import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../../config.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermsAndConditionsComponent implements OnInit {
    
    termsAndConditionsHtml;
    termsAndConditionsVersion;

    constructor(private readonly configService: ConfigService, private readonly sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.termsAndConditionsHtml = this.configService.configSnapshot.termsAndConditionsHtml;
        this.sanitizer.bypassSecurityTrustHtml(this.termsAndConditionsHtml);
        this.termsAndConditionsVersion = this.configService.configSnapshot.termsAndConditionsVersion;
    }
}
