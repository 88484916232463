<mat-card class="quota-breach" *ngIf="currentUser">
    <ng-container *ngIf="currentUser.userPlan === userPlanEnum.free">
        <h3>You have reached your quota limit for the FREE plan</h3>
        <app-quota-errors [privateProjectsCount]="stats.privateCount"
            [totalProjectsCount]="stats.totalCount" [maxPrivateProjects]="config.userPlanFreeMaxPrivateProjects"
            [maxTotalProjects]="config.userPlanFreeMaxTotalProjects">
        </app-quota-errors>
    </ng-container>
    <ng-container *ngIf="currentUser.userPlan === userPlanEnum.silver">
        <h3>You have reached your quota limit for the SILVER plan</h3>
        <app-quota-errors [privateProjectsCount]="stats.privateCount"
            [totalProjectsCount]="stats.totalCount"
            [maxPrivateProjects]="config.userPlanSilverMaxPrivateProjects"
            [maxTotalProjects]="config.userPlanSilverMaxTotalProjects">
        </app-quota-errors>
    </ng-container>
    <ng-container *ngIf="currentUser.userPlan === userPlanEnum.gold">
        <h3>You have reached your quota limit for the GOLD plan</h3>
        <app-quota-errors [privateProjectsCount]="stats.privateCount"
            [totalProjectsCount]="stats.totalCount"
            [maxPrivateProjects]="config.userPlanGoldMaxPrivateProjects"
            [maxTotalProjects]="config.userPlanGoldMaxTotalProjects">
        </app-quota-errors>
    </ng-container>
</mat-card>