import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from '@babylonjs/core';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class ConfigResolver implements Resolve<any> {
  constructor(private readonly configService: ConfigService) {}

  resolve(
  ): Observable<any>|Promise<any>|any {
    return this.configService.getConfig();
  }
}
