import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-quota-errors',
    templateUrl: './quota-errors.component.html',
    styleUrls: ['./quota-errors.component.scss']
})
export class QuotaErrorsComponent implements OnInit {

    @Input()
    privateProjectsCount: number;

    @Input()
    totalProjectsCount: number;

    @Input()
    maxPrivateProjects: number;

    @Input()
    maxTotalProjects: number;

    constructor() { }

    ngOnInit() {
    }

}
