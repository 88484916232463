import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../config.service';
import { Constants } from '../../../constants';
import { DatabaseService } from '../../../database/database.service';
import { Project } from '../../../database/models/project';
import { Script } from '../../../database/models/script';
import { CommunicationService } from '../../../shared/communication.service';

@Component({
    selector: 'app-script-edit',
    templateUrl: './script-edit.component.html',
    styleUrls: ['./script-edit.component.scss']
})
export class ScriptEditComponent implements OnInit {

    project: Project;
    script: Script;
    scriptIdParam = 'scriptId';
    projectIdParam = 'projectId';
    scriptId: string;
    projectId: string;

    form: FormGroup;
    fileExtensionsConsistent = true;
    formSubmitted = false;

    constructor(
        private readonly databaseService: DatabaseService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly comms: CommunicationService,
        private readonly configService: ConfigService
    ) { }

    ngOnInit(): void {
        this.scriptId = this.route.snapshot.params[this.scriptIdParam];
        this.projectId = this.route.snapshot.params[this.projectIdParam];
        this.databaseService.script.getScript(this.scriptId, this.projectId).subscribe((t: Script) => {
            this.script = t;
            this.form = new FormGroup({
                title: new FormControl(t.title, [Validators.required, Validators.maxLength(Constants.smallFieldLength)]),
            });
        });
        this.databaseService.projects.getByIdFb(this.projectId).subscribe(p => {
            this.project = p;
        });
    }

    getErrorMessage(): string {
        let result = '';
        if (this.form.controls.title.hasError('required')) {
            result = 'You must enter a value';
        } else if (this.form.controls.title.hasError('maxlength')) {
            result = `Field contains more than ${Constants.smallFieldLength} characters`;
        }
        return result;
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmitted = true;
            this.comms.openSpinnerSnackBar(`Script is being updated`);
            this.databaseService.script.update(
                {
                    title: this.form.value.title,
                    lastEditedDate: this.databaseService.serverTimestamp(),
                    appVersion: this.configService.configSnapshot.appVersion,
                }, this.scriptId, this.projectId
            ).subscribe(_ => {
                this.comms.openSnackBar(`Script updated successfully`, 'Close', false, Constants.snackbarTimer);
                this.router.navigate(['../../../../'], {
                    relativeTo: this.route,
                });
            }, err => {
                this.comms.openSnackBar(`Error occured when updating the script. Error message: ${err.message}`, 'Close', true);
                this.formSubmitted = false;
            });
        } else {
            this.formSubmitted = false;
        }
    }
}
