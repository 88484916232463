import { Component, Input, OnInit } from '@angular/core';
import { Config } from '../../../database/models/config';
import { ProjectStats } from '../../../database/models/project-stats';
import { UserPrivate } from '../../../database/models/user';
import { userPlanEnum } from '../../../database/models/user-plan.enum';

@Component({
  selector: 'app-script-quota',
  templateUrl: './script-quota.component.html',
  styleUrls: ['./script-quota.component.scss']
})
export class ScriptQuotaComponent implements OnInit {
    @Input()
    currentUser: UserPrivate;

    @Input()
    stats: ProjectStats;

    @Input()
    config: Config;
    
    userPlanEnum = userPlanEnum;

    constructor() { }

    ngOnInit() {
    }


}
