export const environment = {
    version: '0.7.6',
    production: true,
    docsUrl: 'https://docs.bitbybit.dev',
    app: 'https://s-dev.bitbybit.dev',
    preview: 'https://s-dev.bitbybit.dev',
    main: 'https://dev.bitbybit.dev',
    useEmulators: false,
    firebase: {
        apiKey: 'AIzaSyAOwqIaBDpVm8aZXuL2lKiuTEd_nJGbSkA',
        authDomain: 'bit-by-bit-e7a63.firebaseapp.com',
        projectId: 'bit-by-bit-e7a63',
        storageBucket: 'bit-by-bit-e7a63.appspot.com',
        messagingSenderId: '652495346446',
        appId: '1:652495346446:web:f926aac5e515f81d68955f',
        measurementId: 'G-CEDSJX62KV'
    }
};
