import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { CommunicationService } from '../communication.service';

@Component({
    selector: 'app-image-upload-cropper',
    templateUrl: './image-upload-cropper.component.html',
    styleUrls: ['./image-upload-cropper.component.scss']
})
export class ImageUploadCropperComponent implements OnInit {

    // resolutions array needs to go from lowest to highest,
    // resolutions.pop() must return highest res.
    @Input()
    resolutions: { width: number, height: number }[];

    // 1 / 1 or 16 / 9...
    // width and height in resolutions need to match it!
    @Input()
    aspectRatio: number;

    @Input()
    fileNamePrefix: string = 'main';

    @Output()
    finishedCropping = new EventEmitter<File[]>();

    maxRes: { width: number, height: number };

    constructor(
        private readonly comms: CommunicationService
    ) { }

    ngOnInit() {
        this.maxRes = this.resolutions.pop();
    }

    imageChangedEvent: any = '';

    croppedImage: any = '';

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    accept() { 
        const blobMax = this.b64toBlob(this.croppedImage.split(',')[1], 'image/png');
        const fileMax = new File([blobMax], `${this.fileNamePrefix}_${this.maxRes.width}_${this.maxRes.height}.png`, { type: 'image/png' });

        Promise.all(
            this.resolutions.map(res => this.compressImage(this.croppedImage, res.width, res.height))
        ).then(result => {
            const files = result.map((res, index) => {
                const blob = this.b64toBlob((res as string).split(',')[1], 'image/png');
                return new File([blob], `${this.fileNamePrefix}_${this.resolutions[index].width}_${this.resolutions[index].height}.png`, { type: 'image/png' });
            });

            this.finishedCropping.emit([...files, fileMax]);
        })
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded(image: LoadedImage) {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        this.comms.openSnackBar('Failed to load the image, consider saving image to a different file format like jpeg.', 'Close', true);
        // show message
    }
    compressImage(src, newX, newY) {
        return new Promise((res, rej) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                const elem = document.createElement('canvas');
                elem.width = newX;
                elem.height = newY;
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, newX, newY);
                const data = ctx.canvas.toDataURL();
                elem.remove();
                res(data);
            }
            img.onerror = error => rej(error);
        })
    }

    b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
