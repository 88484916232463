import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgreementsComponent } from './agreements/agreements.component';
import { AgreementsGuard } from './agreements/agreements.guard';
import { AuthResolver } from './auth-resolver';
import { AuthComponent } from './auth/auth.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { HowToDeleteAccountComponent } from './how-to-delete-account/how-to-delete-account.component';
import { LoggedInGuard } from './login/logged-in-guard';
import { LoggedOutGuard } from './login/logged-out-guard';
import { LoginComponent } from './login/login.component';
import { PickPlanComponent } from './pick-plan/pick-plan.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ProfileComponent } from './profile/profile.component';
import { PurchasePlanComponent } from './purchase-plan/purchase-plan.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { UpdatedAgreementsSignedGuard } from './updated-agreements/updated-agreements-signed.guard';
import { UpdatedAgreementsComponent } from './updated-agreements/updated-agreements.component';
import { UpdatedAgreementsGuard } from './updated-agreements/updated-agreements.guard';
import { UserDeletionStepsComponent } from './user-deletion-steps/user-deletion-steps.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [
                    LoggedOutGuard
                ]
            }, {
                path: 'sign-up',
                component: SignUpComponent,
                canActivate: [
                    LoggedOutGuard
                ]
            }, {
                path: 'profile',
                canActivate: [
                    LoggedInGuard,
                    UpdatedAgreementsSignedGuard
                ],
                resolve: {
                    user: AuthResolver
                },
                component: ProfileComponent
            }, {
                path: 'profile-edit',
                resolve: {
                    user: AuthResolver
                },
                canActivate: [
                    LoggedInGuard,
                    UpdatedAgreementsSignedGuard
                ],
                component: ProfileEditComponent
            }, {
                path: 'user-deletion-steps',
                resolve: {
                    user: AuthResolver
                },
                canActivate: [
                    LoggedInGuard,
                ],
                component: UserDeletionStepsComponent
            }, {
                path: 'agreements',
                component: AgreementsComponent,
                canActivate: [
                    AgreementsGuard
                ]
            }, {
                path: 'updated-agreements',
                component: UpdatedAgreementsComponent,
                canActivate: [
                    UpdatedAgreementsGuard
                ]
            }, {
                path: 'email-not-verified',
                component: EmailNotVerifiedComponent,
                canActivate: [
                    LoggedInGuard
                ],
            }, {
                path: 'how-to-delete-account',
                component: HowToDeleteAccountComponent
            }
        ],
    },
    {
        path: 'pick-plan',
        component: PickPlanComponent
    }, {
        path: 'purchase-plan/:plan',
        component: PurchasePlanComponent,
    }, {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent
    }, {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    }, {
        path: 'cookies-policy',
        component: CookiePolicyComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
