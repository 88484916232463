import { Component, Input, OnInit } from '@angular/core';
import { Config } from '../../../database/models/config';
import { UserPrivate } from '../../../database/models/user';
import { userPlanEnum } from '../../../database/models/user-plan.enum';

@Component({
    selector: 'app-quota-breach',
    templateUrl: './quota-breach.component.html',
    styleUrls: ['./quota-breach.component.scss']
})
export class QuotaBreachComponent implements OnInit {

    @Input()
    currentUser: UserPrivate;

    @Input()
    stats: {
        privateCount: number,
        totalCount: number, 
    };

    @Input()
    config: Config;

    userPlanEnum = userPlanEnum;
    
    constructor() { }

    ngOnInit() {
    }

}
