import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { AuthResolver } from './auth/auth-resolver';
import { ConfigGuard } from './projects/config.guard';

export function isApp(url: UrlSegment[], group: UrlSegmentGroup) {
    return group.segments.length === 1 && group.segments[0].path.startsWith('app') ? ({ consumed: url }) : null;
}

const routes: Routes = [
    {
        path: '',
        canActivate: [
            ConfigGuard
        ],
        children: [
            {
                path: '',
                loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
                canLoad: [
                    ConfigGuard,
                ]
            },
            { path: 'app', redirectTo: 'app/none/none/none', pathMatch: 'full' },
            {
                path: 'app/:authorPublicUserName/:projectId/:scriptId',
                loadChildren: () => import('./bitbybit-s/bitbybit-s.module').then(m => m.BitbybitSModule),
                canLoad: [
                    ConfigGuard,
                ],
            },
            {
                path: 'app/:authorPublicUserName/:projectId/:scriptId/:preview',
                loadChildren: () => import('./bitbybit-s-preview/bitbybit-s-preview.module').then(m => m.BitbybitSPreviewModule),
                canLoad: [
                    ConfigGuard,
                ],
            },
            {
                path: 'projects',
                loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
                canLoad: [
                    ConfigGuard,
                ]
            },
            {
                path: 'auth',
                loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
                canLoad: [
                    ConfigGuard,
                ]
            }
        ]
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
