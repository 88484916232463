<ng-container *ngIf="rerender">
    <h2>
        <button matTooltip="Back to Projects" *ngIf="clickedProject" mat-icon-button mat-button
            (click)="backToProjects()">
            <mat-icon>arrow_left</mat-icon>
        </button>
        <button matTooltip="Back to All Projects" *ngIf="clickedAuthor && !clickedProject" mat-icon-button mat-button
            (click)="backToAllProjects()">
            <mat-icon>arrow_left</mat-icon>
        </button>
        Projects by <span *ngIf="publicUserName" class="public-user-name">{{publicUserName}}</span> <span
            *ngIf="!publicUserName" class="public-user-name">Bit by bit developers community</span>
    </h2>
    <button *ngIf="!myProjects && publicUserName && userPlan && !clickedProject" (click)="switchToMyProjects()"
        mat-button>Show my projects</button>
    <div class="radios">
        <mat-radio-group *ngIf="userPlan && !clickedProject" aria-label="Project visibility"
            [formControl]="visibilityControl">
            <mat-radio-button [value]="projectVisibilityEnum.public">Public</mat-radio-button>
            <mat-radio-button *ngIf="this.userPlan === userPlanEnum.silver || this.userPlan === userPlanEnum.gold"
                [value]="projectVisibilityEnum.silverCommunity">Silver Community</mat-radio-button>
            <mat-radio-button *ngIf="this.userPlan === userPlanEnum.gold" [value]="projectVisibilityEnum.goldCommunity">
                Gold Community</mat-radio-button>
            <mat-radio-button *ngIf="myProjects" [value]="projectVisibilityEnum.private">Private</mat-radio-button>
        </mat-radio-group>
    </div>
    <app-projects-gallery [hidden]="clickedProject" *ngIf="visibility === projectVisibilityEnum.public" [inModal]="true"
        [visibility]="projectVisibilityEnum.public" [authorId]="userId" [pageSize]="8"
        (clickedOnProject)="clickedOnProject($event)" (clickedOnAuthor)="clickedOnAuthor($event)">
    </app-projects-gallery>
    <app-projects-gallery [hidden]="clickedProject" *ngIf="visibility === projectVisibilityEnum.silverCommunity"
        [inModal]="true" [visibility]="projectVisibilityEnum.silverCommunity" [authorId]="userId" [pageSize]="8"
        (clickedOnProject)="clickedOnProject($event)" (clickedOnAuthor)="clickedOnAuthor($event)">
    </app-projects-gallery>
    <app-projects-gallery [hidden]="clickedProject" *ngIf="visibility === projectVisibilityEnum.goldCommunity"
        [inModal]="true" [visibility]="projectVisibilityEnum.goldCommunity" [authorId]="userId" [pageSize]="8"
        (clickedOnProject)="clickedOnProject($event)" (clickedOnAuthor)="clickedOnAuthor($event)">
    </app-projects-gallery>
    <app-projects-gallery [hidden]="clickedProject" *ngIf="visibility === projectVisibilityEnum.private"
        [inModal]="true" [visibility]="projectVisibilityEnum.private" [authorId]="userId" [pageSize]="8"
        (clickedOnProject)="clickedOnProject($event)" (clickedOnAuthor)="clickedOnAuthor($event)">
    </app-projects-gallery>
    <app-project-details *ngIf="clickedProject" [inModal]="true" [modalProject]="clickedProject"
        (openedScript)="openedScript($event)">
    </app-project-details>
</ng-container>