
import { DatabaseService } from './database.service';
import { ProjectsDb } from './projects.db';
import { AssetsDb } from './assets.db';
import { SharedDbContextService } from './shared-db-context';
import { ScriptDb } from './script.db';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersDb } from './users.db';
import { ConfigDb } from './config.db';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        DatabaseService,
        UsersDb,
        ConfigDb,
        ProjectsDb,
        AssetsDb,
        ScriptDb,
        SharedDbContextService,
    ],
})
export class DatabaseModule { }
