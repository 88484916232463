import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { UserProjectStats } from '../../database/models/user-project-stats';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-user-deletion-steps',
    templateUrl: './user-deletion-steps.component.html',
    styleUrls: ['./user-deletion-steps.component.scss']
})
export class UserDeletionStepsComponent implements OnInit, OnDestroy {

    projectStats: UserProjectStats;
    batchSize = 3;
    totalCountInPreviousStep;
    batchInitialised = false;
    allProjectsRemoved = false;

    statsSubscription;

    constructor(
        private readonly store: AngularFirestore,
        private readonly router: Router,
        private readonly auth: AuthService
    ) { }

    ngOnInit() {
        this.statsSubscription = this.store.collection(`users/${this.auth.userSnapshot.uid}/project-stats`).doc('project-count').valueChanges().subscribe(res => {
            this.projectStats = res;
            if (this.batchInitialised && this.projectStats.totalCount !== 0 && this.totalCountInPreviousStep - this.projectStats.totalCount >= this.batchSize) {
                this.totalCountInPreviousStep = this.projectStats.totalCount;
                this.deleteChunk();
            } else if (this.projectStats.totalCount === 0 && this.batchInitialised) {
                this.batchInitialised = false;
                this.allProjectsRemoved = true;
            }
        })
    }

    ngOnDestroy(){
        if(this.statsSubscription){
            this.statsSubscription.unsubscribe();
        }
    }

    batchDeleteProjects() {
        this.totalCountInPreviousStep = this.projectStats.totalCount;
        this.batchInitialised = true;
        this.deleteChunk();
    }

    deleteChunk() {
        this.store.collection('projects', ref => {
            return ref
                .where('authorId', '==', this.auth.userSnapshot.uid)
                .limit(this.batchSize)
        }).get().subscribe(projects => projects.docs.forEach(doc => doc.ref.delete()));
    }

    deleteAccount(){
        this.auth.deleteAccount().subscribe();
    }

}
