import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from '../auth.service';

@Injectable()
export class AgreementsGuard implements CanActivate {

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const result = this.authService.userDb && this.authService.userDb.uid ? false : true;
        if (!result) {
            this.router.navigate(['/']);
        }
        return result;
    }
}