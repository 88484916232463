<div class="container purchase-plan" *ngIf="config">
    <div class="row">
        <div class="col">
        <h1>Thank you for considering the {{plan.toUpperCase()}} plan</h1>
        <p>
            We are busy implementing automatic Stripe payments, but for now if you want to proceed with the purchase here are the steps to follow:
        </p>
        <br>
        <ol>
            <li>
                Make yourself a free account if you did not do it already by <a routerLink="/auth/sign-up">Signing Up</a>. 
            </li>
            <li>
                Mail us at <a href="mailto:info@bitbybit.dev">info@bitbybit.dev</a> from the email address with which you created your account. We will need this inforamtion:
                <ul>
                    <li>
                        First name
                    </li>
                    <li>
                        Last name
                    </li>
                    <li>
                        Phone number
                    </li>
                    <li>
                        Address
                    </li>
                    <li>
                        Indicate if you want to pay via Bank Transfer or PayPal.
                    </li>
                    <li>
                        If you are a business, indicate your company name, registration code, company address, VAT code if applicable.
                    </li>
                </ul>
            </li>
            <li>
                We will send you the invoice asking to pay <ng-container *ngIf="plan === 'gold'">{{config.userPlanGoldAnnualPriceEuro}}</ng-container> <ng-container *ngIf="plan === 'silver'">{{config.userPlanSilverAnnualPriceEuro}}</ng-container> Euro excl. VAT
            </li>
            <li>
               Make a transfer with the unique ID found in the invoice.
            </li>
            <li>
               We will activate your purchased plan on the account when we receive the payment.
            </li>
        </ol>
        </div>
    </div>
</div>