import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProjectsComponent } from './projects.component';
import { AssetAddComponent } from './assets/asset-add/asset-add.component';
import { DeleteProjectWarningComponent, ProjectsOverviewComponent } from './projects-overview/projects-overview.component';
import { ScriptAddComponent } from './script/script-add/script-add.component';
import { ScriptEditComponent } from './script/script-edit/script-edit.component';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { ProjectEditComponent } from './project-edit/project-edit.component';
import { DatabaseModule } from '../database/database.module';
import { ProjectsRoutingModule } from './projects-routing.module';
import { MaterialModule } from '../material.module';
import { PublicProjectsGalleryComponent } from './public-projects-gallery/public-projects-gallery.component';
import { QuotaBreachComponent } from './projects-overview/quota-breach/quota-breach.component';
import { QuotaErrorsComponent } from './projects-overview/quota-errors/quota-errors.component';
import { AssetQuotaComponent } from './projects-overview/asset-quota/asset-quota.component';
import { ScriptQuotaComponent } from './projects-overview/script-quota/script-quota.component';
import { FileSizePipe } from '../shared/file-size.pipe';
import { ProjectsGalleryComponent } from './projects-gallery/projects-gallery.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { PublicProjectsAuthorGalleryComponent } from './public-projects-author-gallery/public-projects-author-gallery.component';
import { SharedModule } from '../shared/shared.module';
import { ImageAddComponent } from './image-add/image-add.component';
import { ScriptImageAddComponent } from './script/script-image-add/script-image-add.component';

@NgModule({
    declarations: [
        ProjectsComponent,
        AssetAddComponent,
        ProjectsOverviewComponent,
        ScriptAddComponent,
        ScriptEditComponent,
        ProjectCreateComponent,
        ProjectEditComponent,
        PublicProjectsGalleryComponent,
        PublicProjectsAuthorGalleryComponent,
        ProjectsGalleryComponent,
        ProjectDetailsComponent,
        QuotaBreachComponent,
        QuotaErrorsComponent,
        DeleteProjectWarningComponent,
        AssetQuotaComponent,
        ScriptQuotaComponent,
        FileSizePipe,
        ImageAddComponent,
        ScriptImageAddComponent,
   ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        DatabaseModule,
        ProjectsRoutingModule,
        MaterialModule,
        SharedModule
    ],
    exports: [
        ProjectsGalleryComponent,
        ProjectDetailsComponent
    ],
    providers: [
    ],
})
export class ProjectsModule { }
