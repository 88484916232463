<div class="container pick-plan" *ngIf="config">
    <div class="row">
        <div class="col-12 col-sm-4 free">
            <h1>
                FREE
            </h1>
            <dl>
                <dt>
                    Max Public Projects
                </dt>
                <dd>
                    Unlimited *
                </dd>
                <dt>
                    Max Private Projects
                </dt>
                <dd>
                    {{config.userPlanFreeMaxPrivateProjects}}
                </dd>
                <dt>
                    Max Single Asset Size
                </dt>
                <dd>
                    {{config.userPlanFreeMaxSingleAssetsSizeMb}} MB
                </dd>
                <dt>
                    Max Total Assets Per Project
                </dt>
                <dd>
                    {{config.userPlanFreeMaxAssetsPerProject}}
                </dd>
                <dt>
                    Max Total Asset Size For Account
                </dt>
                <dd>
                    {{config.userPlanFreeMaxTotalAssetsSizeMb / 1000}} GB
                </dd>
                <dt>
                    Max Total Scripts Per Project
                </dt>
                <dd>
                    {{config.userPlanFreeMaxScriptsInProject}}
                </dd>
                <dt>
                    Access To Public Projects
                </dt>
                <dd>
                    YES
                </dd>
                <dt>
                    Create Public Projects
                </dt>
                <dd>
                    YES
                </dd>
                <dt>
                    Access To SILVER Community Projects
                </dt>
                <dd>
                    NO
                </dd>
                <dt>
                    Create SILVER Community Projects
                </dt>
                <dd>
                    NO
                </dd>
                <dt>
                    Access To GOLDEN Community Projects
                </dt>
                <dd>
                    NO
                </dd>
                <dt>
                    Create GOLDEN Community Projects
                </dt>
                <dd>
                    NO
                </dd>
                <dt>
                    Annual Subscription Price
                </dt>
                <dd>
                    0.00 Euro / year
                </dd>
            </dl>
        </div>
        <div class="col-12 col-sm-4 silver">
            <h1>
                SILVER
            </h1>
            <a routerLink="/auth/purchase-plan/silver">
                <dl>
                    <dt>
                        Max Public Projects
                    </dt>
                    <dd>
                        Unlimited *
                    </dd>
                    <dt>
                        Max Private Projects
                    </dt>
                    <dd>
                        {{config.userPlanSilverMaxPrivateProjects}}
                    </dd>
                    <dt>
                        Max Single Asset Size
                    </dt>
                    <dd>
                        {{config.userPlanSilverMaxSingleAssetsSizeMb}} MB
                    </dd>
                    <dt>
                        Max Total Assets Per Project
                    </dt>
                    <dd>
                        {{config.userPlanSilverMaxAssetsPerProject}}
                    </dd>
                    <dt>
                        Max Total Asset Size For Account
                    </dt>
                    <dd>
                        {{config.userPlanSilverMaxTotalAssetsSizeMb / 1000}} GB
                    </dd>
                    <dt>
                        Max Total Scripts Per Project
                    </dt>
                    <dd>
                        {{config.userPlanSilverMaxScriptsInProject}}
                    </dd>
                    <dt>
                        Access To Public Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Create Public Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Access To SILVER Community Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Create SILVER Community Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Access To GOLDEN Community Projects
                    </dt>
                    <dd>
                        NO
                    </dd>
                    <dt>
                        Create GOLDEN Community Projects
                    </dt>
                    <dd>
                        NO
                    </dd>
                    <dt>
                        Annual Subscription Price
                    </dt>
                    <dd>
                        {{config.userPlanSilverAnnualPriceEuro}} Euro / year excl. VAT
                    </dd>
                </dl>
            </a>
        </div>
        <div class="col-12 col-sm-4 gold">
            <h1>
                GOLD
            </h1>
            <a routerLink="/auth/purchase-plan/gold">
                <dl>
                    <dt>
                        Max Public Projects
                    </dt>
                    <dd>
                        Unlimited *
                    </dd>
                    <dt>
                        Max Private Projects
                    </dt>
                    <dd>
                        Unlimited *
                    </dd>
                    <dt>
                        Max Single Asset Size
                    </dt>
                    <dd>
                        {{config.userPlanGoldMaxSingleAssetsSizeMb}} MB
                    </dd>
                    <dt>
                        Max Total Assets Per Project
                    </dt>
                    <dd>
                        {{config.userPlanGoldMaxAssetsPerProject}}
                    </dd>
                    <dt>
                        Max Total Asset Size For Account
                    </dt>
                    <dd>
                        {{config.userPlanGoldMaxTotalAssetsSizeMb / 1000}} GB
                    </dd>
                    <dt>
                        Max Total Scripts Per Project
                    </dt>
                    <dd>
                        Unlimited *
                    </dd>
                    <dt>
                        Access To Public Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Create Public Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Access To SILVER Community Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Create SILVER Community Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Access To GOLDEN Community Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Create GOLDEN Community Projects
                    </dt>
                    <dd>
                        YES
                    </dd>
                    <dt>
                        Annual Subscription Price
                    </dt>
                    <dd>
                        {{config.userPlanGoldAnnualPriceEuro}} Euro / year excl. VAT
                    </dd>
                </dl>
            </a>
        </div>
    </div>
    <div class="row">
        <p class="notice">
            Unlimited means that we have set the boundaries of the plan to high values, which we don't think will be
            reached by our users. In case you would reach them we will look into your use case and extend the
            boundaries. In all of these plans the same &nbsp;<a routerLink="/terms-and-conditions">Terms</a> &nbsp;
            and
            &nbsp; <a routerLink="/privacy-policy">Privacy Policy</a>&nbsp; apply.
        </p>
    </div>
</div>