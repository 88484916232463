import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { BitbybitSService } from '../../../bitbybit-s/bitbybit-s.service';
import { Project } from '../../../database/models/project';
import { Script } from '../../../database/models/script';
import { UserPrivate } from '../../../database/models/user';
import { AuthorProjectsComponent } from './author-projects/author-projects.component';
import { ProjectScriptsComponent } from './project-scripts/project-scripts.component';
import { ScriptDetailsComponent } from './script-details/script-details.component';

@Component({
    selector: 'app-navigator-breadcrumb',
    templateUrl: './navigator-breadcrumb.component.html',
    styleUrls: ['./navigator-breadcrumb.component.scss']
})
export class NavigatorBreadcrumbComponent implements OnInit {

    @Input()
    user: UserPrivate

    project: Project;
    script: Script;
    publicUserName: string;

    constructor(
        private readonly bitbybitS: BitbybitSService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
    ) { }

    ngOnInit() {
        this.bitbybitS.selectionBundleSubject$.subscribe(res => {
            this.project = undefined;
            this.script = undefined;
            if (res.project && res.script) {
                this.project = res.project;
                this.script = res.script;
                this.publicUserName = res.publicUserName;
            } else if (res.publicUserName) {
                this.publicUserName = res.publicUserName;
            }
        });
    }

    ngOnDestroy() {

    }

    authorProjects() {
        const dialogRef = this.dialog.open(AuthorProjectsComponent,
            {
                width: '800px',
                height: 'auto',
                maxHeight: 'calc(100vh - 20px)',
                autoFocus: false,
                panelClass: 'dark-panel',
                data: {
                    publicUserName: this.publicUserName
                }
            });
        return dialogRef.afterClosed();
    }

    projectScripts() {
        const dialogRef = this.dialog.open(ProjectScriptsComponent,
            {
                width: '800px',
                height: 'auto',
                maxHeight: 'calc(100vh - 20px)',
                panelClass: 'dark-panel',
                autoFocus: false,
                data: {
                    project: this.project
                }
            });
        return dialogRef.afterClosed();
    }

    scriptDetails() {
        const dialogRef = this.dialog.open(ScriptDetailsComponent,
            {
                width: '800px',
                height: 'auto',
                maxHeight: 'calc(100vh - 20px)',
                panelClass: 'dark-panel',
                autoFocus: false,
                data: {
                    script: this.script
                }
            });
        return dialogRef.afterClosed();
    }
}
