import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { userPlanEnum } from '../../database/models/user-plan.enum';
import { DatabaseService } from '../../database/database.service';
import { ConfigService } from '../../config.service';
import { Constants } from '../../constants';
import { CommunicationService } from '../../shared/communication.service';
import { userStateEnum } from '../../database/models/user-state.enum';

@Component({
    selector: 'app-agreements',
    templateUrl: './agreements.component.html',
    styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent implements OnInit {


    form = new FormGroup({
        publicUserName: new FormControl('', [Validators.required, Validators.minLength(Constants.userNameMinLength), Validators.maxLength(Constants.userNameMaxLength), Validators.pattern('^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$')]),
        termsAndConditions: new FormControl(false, [Validators.requiredTrue]),
        privacyPolicy: new FormControl(false, [Validators.requiredTrue]),
        isAdult: new FormControl(false, [Validators.requiredTrue]),
        allowEmail: new FormControl(false),
    });

    formSubmitted = false;
    deleteFailed = false;
    publicUserNameTakenError = false;

    constructor(
        private readonly dbService: DatabaseService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly auth: AuthService,
        private readonly configService: ConfigService,
        private readonly comms: CommunicationService
    ) { }

    ngOnInit(): void {
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmitted = false;
            const publicUserName = this.form.controls.publicUserName.value;
            this.comms.openSpinnerSnackBar(`Checking public user name`);
            this.dbService.users.publicUserNameExists(publicUserName).subscribe(exists => {
                if (exists) {
                    this.publicUserNameTakenError = true;
                    this.form.controls.publicUserName.setErrors({ taken: true });
                    this.comms.openSnackBar(`Entered public user name is already taken by someone else. Try again.`, 'Close', true, Constants.snackbarTimer);
                } else {
                    this.publicUserNameTakenError = false;
                    this.comms.openSpinnerSnackBar(`Saving agreements.`);
                    this.dbService.users.createUserTransaction({
                        uid: this.auth.userSnapshot.uid,
                        publicUserName: this.form.controls.publicUserName.value,
                        profileImage: {
                            min: '',
                            mid: '',
                            max: '',
                        }
                    }, {
                        uid: this.auth.userSnapshot.uid,
                        isAdult: this.form.controls.isAdult.value,
                        date: this.dbService.serverTimestamp(),
                        lastLoginDate: this.dbService.serverTimestamp(),
                        lastEditedDate: this.dbService.serverTimestamp(),
                        agreedToTermsAndConditionsDate: this.dbService.serverTimestamp(),
                        agreedToTermsAndConditionsVersion: this.configService.configSnapshot.termsAndConditionsVersion,
                        agreedToPrivacyPolicyDate: this.dbService.serverTimestamp(),
                        agreedToPrivacyPolicyVersion: this.configService.configSnapshot.privacyPolicyVersion,
                        userPlan: userPlanEnum.free,
                        userPlanActivatedDate: this.dbService.serverTimestamp(),
                        userPlanExpiresInDays: 365 * 100, // free is for ~100 years ;)
                        agreedToReceiveEmails: this.form.controls.allowEmail.value,
                        state: userStateEnum.active
                    }).subscribe({
                        next: p => {
                            setTimeout(() => {
                                this.comms.openSnackBar(`Agreement information successfully saved`, 'Close', false, Constants.snackbarTimer);
                                this.router.navigate(['../../projects/my'], {
                                    relativeTo: this.route,
                                    queryParams: { reload: 'true' }
                                });
                            }, 4000);
                        }, error: err => {
                            this.comms.openSnackBar(`Something went wrong when saving the agreements. Error message: ${err.message}`, 'Close', true);
                        }
                    });
                }
            })

        } else {
            this.formSubmitted = true;
        }
    }

    getErrorMessage(): string {
        let result = '';
        let control = this.form.controls.publicUserName;
        if (control.hasError('required')) {
            result = 'You must enter a value';
        } else if (control.hasError('maxlength')) {
            result = `Field contains more than ${Constants.userNameMaxLength}  characters`;
        } else if (control.hasError('minlength')) {
            result = `Field contains less than ${Constants.userNameMinLength}  characters`;
        } else if (control.hasError('taken')) {
            result = `This username is already taken, please consider another one`;
        } else if (control.hasError('pattern')) {
            result = `Can contain english alphabet letters, digits, spaces, dashes and underscores. Must begin and end with letters or digits.`;
        }
        return result;
    }

    deleteAccount() {
        this.auth.deleteAccount().subscribe(r => {
            this.router.navigate(['../../']);
        }, err => {

        });
    }
}
