import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';
import { Constants } from '../../constants';
import { Project } from '../../database/models/project';
import { projectVisibilityEnum } from '../../database/models/project-visibility.enum';
import { CommunicationService } from '../../shared/communication.service';

@Component({
    selector: 'app-projects-gallery',
    templateUrl: './projects-gallery.component.html',
    styleUrls: ['./projects-gallery.component.scss']
})
export class ProjectsGalleryComponent implements OnInit {

    @Input()
    visibility: projectVisibilityEnum;

    @Input()
    pageSize = 16;

    @Input()
    authorId: string;

    @Input()
    inModal: boolean;

    @Output()
    clickedOnProject = new EventEmitter<Project>();

    @Output()
    clickedOnAuthor = new EventEmitter<{uid: string, publicUserName: string}>();

    projects: Project[];
    pages: any[][] = [];

    currentPage = 1;
    maxPage = 0;

    constructor(
        private readonly store: AngularFirestore,
        private readonly comms: CommunicationService
    ) { }

    ngOnInit(): void {
        this.getProjects(undefined);
    }

    getProjects(title: string) {
        if (this.pages.length < this.currentPage) {
            this.comms.openSpinnerSnackBar(`Loading the projects`);
            this.makeProjectsQuery(title, this.visibility, this.pageSize)
                .pipe(take(1)).subscribe(p => {
                    const projects = this.getProjectsFromDocs(p);
                    if (projects.length === this.pageSize) {
                        this.projects = projects as any;
                        this.pages.push(p.docs);
                    } else if (projects.length === 0) {
                        this.maxPage = this.currentPage - 1;
                        this.currentPage -= 1;
                    } else if (projects.length < this.pageSize) {
                        this.projects = projects as any;
                        this.maxPage = this.currentPage;
                        this.pages.push(p.docs);
                    }
                    this.comms.openSnackBar(`Projects loaded.`, 'Close', false, Constants.snackbarShortTimer);
                }, err => {
                    this.comms.openSnackBar(`Error happened when loading the proejcts. Error message: ${err.message}`, 'Close', true);
                });
        } else {
            this.projects = this.pages[this.currentPage - 1].map(d => this.getDataWithDocId(d));
        }

    }

    private makeProjectsQuery(title: string, visibility: string, limit: number) {
        return this.store.collection('projects', ref => {
            let basic;
            if (title) {
                basic = ref.where('title', '>=', title).where('title', '<=', title + '\uf8ff');
            } else {
                basic = ref;
            }

            if (this.authorId) {
                basic = basic.where('authorId', '==', this.authorId);
            }

            basic = basic
                .where('visibility', '==', visibility)
                .orderBy('lastEditedDate', 'desc')
                .orderBy('title');

            if (this.pages.length > 0) {
                const lastPage = this.pages[this.pages.length - 1];
                const lastProjectDoc = lastPage[lastPage.length - 1];
                basic = basic
                    .startAfter(lastProjectDoc)
                    .limit(limit);
            } else {
                basic = basic.limit(limit);
            }
            return basic;
        }).get();
    }

    nextPage() {
        this.currentPage += 1;
        this.getProjects(undefined)
    }

    previousPage() {
        this.currentPage -= 1;
        this.getProjects(undefined)
    }

    getRouteFromVisibility(visibility: projectVisibilityEnum) {
        let route = '';
        if (visibility === projectVisibilityEnum.public) {
            route = 'public';
        } else if (visibility === projectVisibilityEnum.silverCommunity) {
            route = 'silver';
        } else if (visibility === projectVisibilityEnum.goldCommunity) {
            route = 'gold';
        }
        return route;
    }

    projectOpened(event, project: Project) {
        event.stopPropagation();
        this.clickedOnProject.emit(project)
    }

    authorOpened(event, publicUserName: string, uid: string) {
        event.stopPropagation();
        // this.authorId = publicUserName;
        
        // this.projects = undefined;
        // this.pages = [];
        
        // this.currentPage = 1;
        // this.maxPage = 0;
        // this.getProjects(undefined);
        this.clickedOnAuthor.emit({uid, publicUserName});
    }

    private getProjectsFromDocs(p) {
        return p.docs.map(d => this.getDataWithDocId(d));
    }

    private getDataWithDocId(d): any {
        return {
            id: d.id,
            ...d.data() as any[]
        };
    }

}
