import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Constants } from '../../constants';
import { DatabaseService } from '../../database/database.service';
import { CommunicationService } from '../../shared/communication.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

    form;

    fileExtensionsConsistent = true;
    formSubmitted = false;
    deleteFailed = false;

    constructor(
        private readonly dbService: DatabaseService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly auth: AuthService,
        private readonly comms: CommunicationService
    ) { }

    ngOnInit(): void {
        this.form = new FormGroup({
            allowEmail: new FormControl(this.auth.userDb.agreedToReceiveEmails),
        });
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmitted = false;
            this.dbService.users.updatePrivate(
                {
                    lastEditedDate: this.dbService.serverTimestamp(),
                    agreedToReceiveEmails: this.form.controls.allowEmail.value,
                },
                this.auth.userSnapshot.uid
            ).subscribe(p => {
                this.auth.userDb = undefined;
                this.comms.openSnackBar(`Profile information successfully updated`, 'Close', false, Constants.snackbarTimer);
                this.router.navigate(['../profile'], {
                    relativeTo: this.route,
                });
            });
        } else {
            this.formSubmitted = true;
        }
    }

}
