<h2>Deleting your Account</h2>
<div class="row separate">
    <div class="col-12">
        You can delete your account at any time by clicking on your profile picture,
        going to the Profile information page and clicking the 'Delete my Account' button. You will be
        asked to
        first remove all of the projects in an automated batch process. After all of your
        projects are
        deleted you will need to log out, login again, go to the same page and click Delete
        Account to finalize complete removal of your Account from our database. If for some reason you can not
        delete your account let us know on <a href="mailto:info@bitbybit.dev">info@bitbybit.dev</a>. Mail us only
        from the email address with which you are registered on Bit by bit developers Platform.
    </div>
</div>
<div class="row separate">
    <div class="col-12">
        Deletion of your Account is immediate and irreversible. All data associated with your
        Account after
        deletion will
        be lost forever. MB "Bit by bit developers" will not be able to retrieve it. You agree
        that MB "Bit by bit developers" may keep the information in the form of the archived
        backups of our database. We will do our best to remove that information from backups if
        that is commercially reasonable after you express such intent to us.
    </div>
</div>
<div class="row separate">
    <div class="col-12">
        If you delete your Account while still being an active Silver or Gold plan holder you
        will
        not be compensated for the remaining time period during which you will not use Bit by
        bit developers Platform. After you delete your account you will be removed from any
        mailing lists that we may have and you will not be charged any additional costs
        afterwards when your plans expire. If you decide to register again you will have to
        purchase a plan of your choice again. All plans expire immediately with the removal of
        your Account. It is not possible to reassign ol plans of deleted Accounts to newly
        registered or existing Accounts even if they
        come from the
        same email or third party authentication provider.
    </div>
</div>

<div class="row separate">
    <div class="col-12">
        More information on how, when and why your account can be Deleted you can find in our
        <a routerLink="/terms-and-conditions">Terms and Conditions</a>
    </div>
</div>
