import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../config.service';
import { Config } from '../../database/models/config';

@Component({
    selector: 'app-purchase-plan',
    templateUrl: './purchase-plan.component.html',
    styleUrls: ['./purchase-plan.component.scss']
})
export class PurchasePlanComponent implements OnInit {
    config: Config;
    plan: 'gold' | 'silver';
    
    constructor(
        private readonly configService: ConfigService,
        private readonly route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.config = this.configService.configSnapshot;
        this.plan = this.route.snapshot.params['plan'];
    }

}
