import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Script } from 'projects/bitbybit/src/app/database/models/script';

@Component({
  selector: 'app-script-details',
  templateUrl: './script-details.component.html',
  styleUrls: ['./script-details.component.scss']
})
export class ScriptDetailsComponent implements OnInit {

    script: Script;

    constructor(
        public dialogRef: MatDialogRef<ScriptDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
        this.script = this.data.script;
    }

    close(){
        this.dialogRef.close();
    }
}
