import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigDb } from './database/config.db';
import { Config } from './database/models/config';

@Injectable({ providedIn: 'root' })
export class ConfigService {

    configSnapshot: Config;

    constructor(private readonly configDb: ConfigDb) {
    }

    getConfig(): Observable<Config> {
        if (this.configSnapshot) {
            return of(this.configSnapshot);
        } else {
            return this.configDb.getConfig('0').pipe(map(config => {
                this.configSnapshot = config;
                return config;
            }));
        }
    }
}
