import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsComponent } from './projects.component';
import { AssetAddComponent } from './assets/asset-add/asset-add.component';
import { ProjectsOverviewComponent } from './projects-overview/projects-overview.component';
import { ScriptAddComponent } from './script/script-add/script-add.component';
import { ScriptEditComponent } from './script/script-edit/script-edit.component';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { ProjectEditComponent } from './project-edit/project-edit.component';
import { AuthGuard } from '../auth/auth.guard';
import { PublicProjectsGalleryComponent } from './public-projects-gallery/public-projects-gallery.component';
import { AuthResolver } from '../auth/auth-resolver';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { PublicProjectsAuthorGalleryComponent } from './public-projects-author-gallery/public-projects-author-gallery.component';
import { projectVisibilityEnum } from '../database/models/project-visibility.enum';
import { UpdatedAgreementsSignedGuard } from '../auth/updated-agreements/updated-agreements-signed.guard';
import { ImageAddComponent } from './image-add/image-add.component';
import { ScriptImageAddComponent } from './script/script-image-add/script-image-add.component';

const routes: Routes = [
    {
        path: '',
        component: ProjectsComponent,
        children: [
            {
                path: 'my',
                component: ProjectsOverviewComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
            },
            {
                path: 'my/:projectId/assets/add',
                component: AssetAddComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
            },
            {
                path: 'my/:projectId/script/add',
                component: ScriptAddComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
            },
            {
                path: 'my/:projectId/image',
                component: ImageAddComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
            },
            {
                path: 'my/:projectId/script/:scriptId/edit',
                component: ScriptEditComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
            },
            {
                path: 'my/:projectId/script/:scriptId/image',
                component: ScriptImageAddComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
            },
            {
                path: 'my/add',
                component: ProjectCreateComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
            },
            {
                path: 'my/:projectId/edit',
                component: ProjectEditComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
            },
            {
                path: 'gold',
                component: PublicProjectsGalleryComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
                resolve: {
                    userData: AuthResolver
                },
                data: {
                    visibility: projectVisibilityEnum.goldCommunity
                }
            },
            {
                path: 'gold/:projectId',
                component: ProjectDetailsComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
                resolve: {
                    userData: AuthResolver
                },
                data: {
                    visibility: projectVisibilityEnum.goldCommunity
                }
            },
            {
                path: 'gold/author/:publicUserName',
                component: PublicProjectsAuthorGalleryComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
                resolve: {
                    userData: AuthResolver
                },
                data: {
                    visibility: projectVisibilityEnum.goldCommunity
                }
            },
            {
                path: 'silver',
                component: PublicProjectsGalleryComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
                resolve: {
                    userData: AuthResolver
                },
                data: {
                    visibility:  projectVisibilityEnum.silverCommunity
                }
            },
            {
                path: 'silver/:projectId',
                component: ProjectDetailsComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
                resolve: {
                    userData: AuthResolver
                },
                data: {
                    visibility: projectVisibilityEnum.silverCommunity
                }
            },
            {
                path: 'silver/author/:publicUserName',
                component: PublicProjectsAuthorGalleryComponent,
                canActivate: [
                    AuthGuard,
                    UpdatedAgreementsSignedGuard
                ],
                resolve: {
                    userData: AuthResolver
                },
                data: {
                    visibility: projectVisibilityEnum.silverCommunity
                }
            },
            {
                path: 'public',
                component: PublicProjectsGalleryComponent,
                resolve: {
                    userData: AuthResolver
                },
                data: {
                    visibility: projectVisibilityEnum.public
                }
            },
            {
                path: 'public/:projectId',
                component: ProjectDetailsComponent,
                resolve: {
                    userData: AuthResolver
                },
                data: {
                    visibility: projectVisibilityEnum.public
                }
            },
            {
                path: 'public/author/:publicUserName',
                component: PublicProjectsAuthorGalleryComponent,
                resolve: {
                    userData: AuthResolver
                },
                data: {
                    visibility: projectVisibilityEnum.public
                }
            },
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
