<ng-container *ngIf="project && script">
    <h2>Add Image To Script "{{script.title}} In Project "{{project.title}}"</h2>
    <p>
        Upload the image of at least 960x960 px resolution. Crop the image and when you are satisfied click upload.
    </p>
    <ng-container *ngFor="let filePercentage of filePercentages">
        <ng-container *ngIf="filePercentage.name">
            <h3>Uploading {{filePercentage.name}}</h3>
            <mat-progress-bar [color]="color" [mode]="mode" [value]="filePercentage.percentage"
                [bufferValue]="bufferValue">
            </mat-progress-bar>
        </ng-container>
    </ng-container>
    <app-image-upload-cropper [hidden]="uploadStarted" [resolutions]="resolutions" [aspectRatio]="1 / 1" fileNamePrefix="main"
        (finishedCropping)="imagesMade($event)"></app-image-upload-cropper>
</ng-container>