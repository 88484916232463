import { Injectable } from '@angular/core';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { DefaultEntity } from './models/default-entity';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, map } from 'rxjs/operators';
import { CommunicationService } from '../shared/communication.service';
import firebase from 'firebase/compat/app';

@Injectable()
export class SharedDbContextService {

    db: IDBDatabase;

    constructor(
        private readonly store: AngularFirestore,
        private readonly comms: CommunicationService
    ) { }

    getByIds<T>(ids: number[], storeName: string): Observable<T[]> {
        const { store, subject } = this.getSubjectStoreAndTransaction<T>(storeName, 'readonly');
        const results = [];
        ids.forEach((id) => {
            const request = store.get(id);
            request.onsuccess = (e) => {
                results.push((e.target as any).result);
                if (results.length === ids.length) {
                    subject.next(results);
                    subject.complete();
                }
            };
            request.onerror = (e) => {
                results.push(undefined);
                if (results.length === ids.length) {
                    subject.next(results);
                    subject.complete();
                }
            };
        });
        return subject.asObservable();
    }

    getById<T>(id: number, storeName: string): Observable<T> {
        const { store, subject, tx } = this.getSubjectStoreAndTransaction<T>(storeName, 'readonly');
        const request = store.get(id);
        return this.handleStates<T>(request, subject, tx);
    }

    getByIdFb<T>(id: string, storeName: string): Observable<T> {
        return this.store.collection<T>(storeName).doc(id).get().pipe(map(s => ({id: s.id, ...s.data()})));
    }


    getAll<T>(indexName: string, value: any, storeName: string): Observable<T> {
        const { store, subject, tx } = this.getSubjectStoreAndTransaction<T>(storeName, 'readonly');
        const index = store.index(indexName);
        const request = index.getAll(value);
        return this.handleStates<T>(request, subject, tx);
    }

    createOrUpdate<T>(object: T, storeName: string, id?: number): Observable<T> {
        const { store, subject, tx } = this.getSubjectStoreAndTransaction<T>(storeName, 'readwrite');
        let request;
        if (id !== undefined) {
            const r = store.get(id);
            r.onsuccess = (e) => {
                const item = (e.target as any).result;
                (object as DefaultEntity).lastEditedDate = Date.now();
                const objToUpdate = { ...item, ...object };
                request = store.put(objToUpdate);
                this.setUpHandles(request, subject, tx);
            };
            r.onerror = (err) => {
                subject.error(err);
                subject.complete();
            };
        } else {
            request = store.put(object);
            this.setUpHandles(request, subject, tx);
        }
        return subject.asObservable();
    }

    createOrUpdateFb<T>(object: T, path: string, id?: number): Observable<T> {
        const subject = new Subject<any>();
        if (id !== undefined) {
            this.comms.openSpinnerSnackBar('Update operation in progress...');
            this.store.collection(path).doc(id.toString()).update(object).then(s => {
                subject.next(s);
                subject.complete();
            }, err => {
                subject.error(err);
                this.comms.openSnackBar(`Update failed with message: ${err.message}`, 'Close', true);
                subject.complete();
            });
        } else {
            this.comms.openSpinnerSnackBar('Create operation in progress...');
            this.store.collection(path).add(object).then(s => {
                subject.next(s);
                subject.complete();
            }, err => {
                subject.error(err);
                this.comms.openSnackBar(`Create operation failed with message: ${err.message}`, 'Close', true);
                subject.complete();
            });
        }
        return subject.asObservable();
    }

    delete<T>(id: number, storeName: string): Observable<T> {
        const { store, subject, tx } = this.getSubjectStoreAndTransaction<T>(storeName, 'readwrite');
        const request = store.delete(id);
        return this.handleStates<T>(request, subject, tx);
    }

    deleteFb(id: string, storeName: string): Observable<void> {
        this.comms.openSpinnerSnackBar('Delete operation in progress...');
        return from(this.store.collection(storeName).doc(id.toString()).delete()).pipe(
            catchError(err => {
                this.comms.openSnackBar(`Delete operation failed with message: ${err.message}`, 'Close', true);
                return throwError(err);
            })
        );
    }

    private handleStates<T>(request: IDBRequest<IDBValidKey>, subject: Subject<T>, tx: IDBTransaction): Observable<T> {
        this.setUpHandles(request, subject, tx);
        return subject.asObservable();
    }

    private setUpHandles(request: any, subject: Subject<any>, tx: IDBTransaction): void {
        request.onsuccess = (e: Event) => {
            subject.next((e.target as any).result);
            subject.complete();
        };

        request.onerror = (event: Event) => {
            subject.error((event.target as any).error);
            subject.complete();
        };

        tx.onabort = () => {
            subject.error('aborted');
            subject.complete();
        };
    }
    private getSubjectStoreAndTransaction<T>(storeName: string, mode: 'readwrite' | 'readonly'): { subject: Subject<any>, tx: IDBTransaction, store: IDBObjectStore } {
        const subject = new Subject<T>();
        const tx = this.db.transaction(storeName, mode);
        const store = tx.objectStore(storeName);
        return { store, subject, tx };
    }

    serverTimestamp() {
        return (firebase as any).firestore.FieldValue.serverTimestamp();
    };

    increment(count: number) {
        return (firebase as any).firestore.FieldValue.increment(count);
    };

}
