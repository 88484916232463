import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class LoggedInGuard implements CanActivate {

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isLoggedIn().pipe(map(authInfo => {
            const result = authInfo.user && authInfo.user.uid ? true : false;
            if (!result) {
                this.router.navigate(['/']);
            }
            return result;
        }));
    }
}