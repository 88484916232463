import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { projectVisibilityEnum } from 'functions/src/interfaces/project-visibility.enum';
import { DatabaseService } from 'projects/bitbybit/src/app/database/database.service';
import { Project } from 'projects/bitbybit/src/app/database/models/project';
import { userPlanEnum } from 'projects/bitbybit/src/app/database/models/user-plan.enum';
import { AuthService } from '../../../auth.service';

@Component({
    selector: 'app-author-projects',
    templateUrl: './author-projects.component.html',
    styleUrls: ['./author-projects.component.scss']
})
export class AuthorProjectsComponent implements OnInit {

    publicUserName: string;
    visibilityControl = new FormControl(projectVisibilityEnum.public);
    visibility: projectVisibilityEnum = projectVisibilityEnum.public
    userId: string;
    clickedProject: Project;
    clickedAuthor = false;

    projectVisibilityEnum = projectVisibilityEnum;
    userPlan;
    userPlanEnum = userPlanEnum;
    myProjects: boolean;
    rerender = false;

    constructor(
        public dialogRef: MatDialogRef<AuthorProjectsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly databaseService: DatabaseService,
        private readonly auth: AuthService
    ) { }

    ngOnInit() {
        this.publicUserName = this.data.publicUserName;
        if (this.publicUserName) {
            this.databaseService.users.getPublicUserNameData(this.publicUserName).subscribe(user => {
                this.userId = user.uid;
                if (this.auth.userDb) {
                    this.userPlan = this.auth.userDb.userPlan;
                    if (this.auth.userDb.uid === user.uid) {
                        this.myProjects = true;
                    }
                }
                this.rerender = true;
            });
        } else {
            if (this.auth.userDb) {
                this.userPlan = this.auth.userDb.userPlan;
            }
            this.rerender = true;
        }
        this.visibilityControl.valueChanges.subscribe(value => {
            this.visibility = value;
        });
    }

    clickedOnProject(project) {
        this.clickedProject = project;
    }

    clickedOnAuthor(data) {
        if (this.publicUserName !== data.publicUserName || this.userId !== data.uid) {
            this.publicUserName = data.publicUserName;
            this.userId = data.uid;
            this.clickedAuthor = true;
            if(this.auth.userDb){
                this.myProjects = this.auth.userDb.uid === this.userId;
            }
            setTimeout(() => {
                this.rerender = false;
                setTimeout(() => {
                    this.rerender = true;
                })
            });
        }
    }
    backToAllProjects() {
        this.publicUserName = '';
        this.userId = '';
        this.clickedAuthor = false;
        setTimeout(() => {
            this.rerender = false;
            setTimeout(() => {
                this.rerender = true;
            })
        });
    }

    backToProjects() {
        this.clickedProject = undefined;
    }



    openedScript(script) {
        this.dialogRef.close();
    }

    switchToMyProjects() {
        this.userId = '';
        setTimeout(() => {
            this.userId = this.auth.userDb.uid;
            this.publicUserName = this.auth.userDb.publicUserName;
            this.clickedProject = undefined;
            this.clickedAuthor = false;
            this.myProjects = true;
            this.rerender = false;
            setTimeout(() => {
                this.rerender = true;
            })
        });
    }
}
