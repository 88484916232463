<ng-container *ngIf="project">
    <h2>Edit The Project "{{project.title}}"</h2>
    <form *ngIf="form" [formGroup]="form" (submit)="submit()">
        <div>
            <mat-form-field color="accent" appearance="fill">
                <mat-label>Title</mat-label>
                <input required matInput formControlName="title">
                <mat-error *ngIf="form.controls['title'].invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
            <mat-form-field color="accent" appearance="fill">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description"></textarea>
                <mat-error *ngIf="form.controls['description'].invalid">{{getErrorMessageDescription()}}</mat-error>
            </mat-form-field>
            <mat-radio-group aria-label="Project visibility" formControlName="visibility">
                <mat-radio-button [value]="projectVisibilityEnum.private">Private</mat-radio-button>
                <mat-radio-button [value]="projectVisibilityEnum.public">Public</mat-radio-button>
                <mat-radio-button *ngIf="this.userPlan === userPlanEnum.silver || this.userPlan === userPlanEnum.gold"
                    [value]="projectVisibilityEnum.silverCommunity">Silver Community</mat-radio-button>
                <mat-radio-button *ngIf="this.userPlan === userPlanEnum.gold"
                    [value]="projectVisibilityEnum.goldCommunity">Gold Community</mat-radio-button>
            </mat-radio-group>
        </div>
        <button *ngIf="!this.formSubmitted" class="form-submit" mat-button mat-primary-button>Submit</button>
    </form>
</ng-container>