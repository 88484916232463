<div
    *ngIf="privateProjectsCount >= maxPrivateProjects && totalProjectsCount < maxTotalProjects">
    You have {{privateProjectsCount}} private projects out of
    {{maxPrivateProjects}} allowed.
    Please consider <a routerLink="/auth/pick-plan">upgrading your plan</a>, removing some private
    projects or
    making some of your private projects public.
</div>
<div
    *ngIf="privateProjectsCount >= maxPrivateProjects && totalProjectsCount >= maxTotalProjects">
    You have {{privateProjectsCount}} private projects out of
    {{maxPrivateProjects}} allowed and {{totalProjectsCount}} total projects out of
    {{maxTotalProjects}} allowed.
    Please consider <a routerLink="/auth/pick-plan">upgrading your plan</a> or removing some projects.
</div>
<div
    *ngIf="privateProjectsCount < maxPrivateProjects && totalProjectsCount >= maxTotalProjects">
    You have {{totalProjectsCount}} projects in total out of
    {{maxTotalProjects}} allowed.
    Please consider <a routerLink="/auth/pick-plan">upgrading your plan</a> or removing some projects.
</div>