<ng-container *ngIf="project">
    <h1>{{project.title}}</h1>
    <div class="row">
        <div class="col-12 col-sm-6">
            <img class="project-image" [ngClass]="{picturePlaceholder: project.image.max ? false : true}"
                alt="Project Image" [src]="project.image.max ? project.image.max : 'assets/image-placeholder.png'" />
        </div>
        <div class="col-12 col-sm-6">
            <dl class="descriptions">
                <div>
                    <dt>Author</dt>
                    <dd>
                        <a *ngIf="!inModal"
                            [routerLink]="'/projects/' + getRouteFromVisibility(visibility) + '/author/' + project.authorPublicUserName">
                            {{project.authorPublicUserName}}
                        </a>
                        <span *ngIf="inModal">
                            {{project.authorPublicUserName}}
                        </span>
                    </dd>
                </div>
                <div>
                    <dt>Description</dt>
                    <dd>{{project.description}}</dd>
                </div>
                <div>
                    <dt>Visibility</dt>
                    <dd>{{project.visibility}}</dd>
                </div>
                <div>
                    <dt>Date Created</dt>
                    <dd *ngIf="project.date">{{project.date.toDate() | date:'medium'}}</dd>
                </div>
                <div>
                    <dt>Last Edit Date</dt>
                    <dd *ngIf="project.lastEditedDate">{{project.lastEditedDate.toDate() | date:'medium'}}</dd>
                </div>
            </dl>
        </div>
    </div>

    <br>
    <mat-card class="category">
        <mat-card-title>
            <h4>Scripts</h4>
        </mat-card-title>
        <ng-container *ngIf="project.scripts && project.scripts.length > 0">
            <ng-container *ngFor="let ts of project.scripts">
                <mat-card>
                    <mat-card-title>
                        <h5>{{ts.title}}</h5>
                        <span class="actions">
                            <button matTooltip="Launch script" mat-mini-fab aria-label="Launch script"
                                (click)="scriptLaunch(ts)">
                                <mat-icon>launch</mat-icon>
                            </button>
                        </span>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                <button matTooltip="Launch script" class="image-button" (click)="scriptLaunch(ts)">
                                    <img class="script-image"
                                        [ngClass]="{picturePlaceholder: ts.image.min ? false : true}"
                                        [src]="ts.image.min ? ts.image.min : 'assets/image-placeholder.png'" />
                                </button>
                            </div>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-9">
                                <dl class="descriptions">
                                    <div>
                                        <dt>Type</dt>
                                        <dd>
                                            <img *ngIf="ts.type==='typescript'" class="script-type"
                                                src="assets/typescript_logo_mini.png" />
                                            <img *ngIf="ts.type==='blockly'" class="script-type"
                                                src="assets/blockly_logo_mini.png" />
                                            <img *ngIf="ts.type==='rete'" class="script-type"
                                            src="assets/rete_logo_mini.png" />
                                            <div>
                                                {{ts.type}}
                                            </div>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>Date Created</dt>
                                        <dd>{{ts.date.toDate() | date:'medium'}}</dd>
                                    </div>
                                    <div>
                                        <dt>Last Edit Date</dt>
                                        <dd>{{ts.lastEditedDate.toDate() | date:'medium'}}</dd>
                                    </div>

                                </dl>
                            </div>
                        </div>
                    </mat-card-subtitle>
                </mat-card>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="project.scripts === undefined">
            <mat-spinner matTooltip="Scripts are being loaded" color="accent" diameter="30">
            </mat-spinner>
            Loading scripts
        </ng-container>
        <ng-container *ngIf="project.scripts !== undefined && project.scripts.length === 0">
            <span class="empty-list">
                This project does not have any scripts.
            </span>
        </ng-container>
    </mat-card>
</ng-container>