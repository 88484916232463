import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatabaseService } from 'projects/bitbybit/src/app/database/database.service';
import { Project } from 'projects/bitbybit/src/app/database/models/project';
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'app-project-scripts',
  templateUrl: './project-scripts.component.html',
  styleUrls: ['./project-scripts.component.scss']
})
export class ProjectScriptsComponent implements OnInit {

    userId: string;
    project: Project;
    userPlan;
    myProjects: boolean;

    constructor(
        public dialogRef: MatDialogRef<ProjectScriptsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly databaseService: DatabaseService,
        private readonly auth: AuthService
    ) { }

    ngOnInit() {
        this.project = this.data.project;
    }

    openedScript(script) {
        this.dialogRef.close();
    }

}
