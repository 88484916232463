import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Script } from './models/script';
@Injectable()
export class ScriptDb {

    constructor(
        private readonly store: AngularFirestore
    ) { }

    getScripts(projectId, authorId): Observable<Script[]> {
        const ref = this.store.collection(`projects/${projectId}/scripts`, ref => ref.where('authorId', '==', authorId));
        return ref.get().pipe(take(1), map(res => {
            return res.docs.map(doc => {
                const docData: Script = doc.data();
                return { id: doc.id, ...docData };
            });
        }));
    }

    getScript(scriptId: string, projectId: string): Observable<Script> {
        const ref = this.store.collection(`projects/${projectId}/scripts`).doc(scriptId)
        return ref.get().pipe(map(res => {
            const data = res.data() as Script;
            return {
                id: res.id, ...data
            };
        }));
    }

    create(script: Script): Observable<void> {
        const ref = this.store.collection(`projects/${script.projectId}/scripts`).doc()
        return from(ref.set(script));
    }

    update(script: Script, scriptId: string, projectId: string): Observable<void> {
        const ref = this.store.collection(`projects/${projectId}/scripts`).doc(scriptId)
        return from(ref.update(script));
    }

    delete(script: Script): Observable<void> {
        const ref = this.store.collection(`projects/${script.projectId}/scripts`).doc(script.id)
        return from(ref.delete());
    }
}
