<h2>
    <ng-container *ngIf="visibility === projectVisibilityEnum.public">Public Projects</ng-container>
    <ng-container *ngIf="visibility === projectVisibilityEnum.goldCommunity">Gold Community Projects</ng-container>
    <ng-container *ngIf="visibility === projectVisibilityEnum.silverCommunity">Silver Community Projects</ng-container>
</h2>
<p>
    <ng-container *ngIf="visibility === projectVisibilityEnum.public">
        Here you find the projects which are created by our community members and shared with the world.
        <ng-container *ngIf="!loggedIn">
            <a routerLink="/auth/sign-up">Join other 'Bit by bit developers' by signing up</a> - get access to exclusive
            community
            projects and share your own creative CAD scripts.
        </ng-container>
        Check out <a routerLink="/auth/pick-plan">our plan offerings</a>.
    </ng-container>
    <ng-container *ngIf="visibility === projectVisibilityEnum.goldCommunity">Here you find the exclusive Gold community
        projects created by professional 'Bit by bit developers'.</ng-container>
    <ng-container *ngIf="visibility === projectVisibilityEnum.silverCommunity">
        Here you find the exclusive Silver community projects created by professional 'Bit by bit developers'.
        Check out <a routerLink="/auth/pick-plan">our plan offerings</a>.
    </ng-container>
</p>
<app-projects-gallery [visibility]="visibility" [pageSize]="16"></app-projects-gallery>