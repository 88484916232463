import { Injectable } from '@angular/core';
import { forkJoin, from, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { UserPrivate, UserPublic } from '../database/models/user';
import { Router } from '@angular/router';
import { UsersDb } from '../database/users.db';
import { ConfigService } from '../config.service';
import { CommunicationService } from '../shared/communication.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from 'firebase/auth';
import firebase from 'firebase/compat/app';

@Injectable()
export class AuthService {

    readonly user: Subject<User> = new Subject();
    userSnapshot: User;
    userDb: UserPrivate & UserPublic;

    constructor(
        private readonly afAuth: AngularFireAuth,
        private readonly dbUsersService: UsersDb,
        private readonly router: Router,
        private readonly configService: ConfigService,
        private readonly comms: CommunicationService,
    ) { }

    doGoogleLogin() {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        return from(this.afAuth.signInWithPopup(provider)).pipe(
            take(1),
            mergeMap(() => {
                return this.afAuth.authState.pipe(map(user => {
                    this.userSnapshot = user;
                    this.user.next(user);
                }))
            })
        );
    }

    doFacebookLogin() {
        const provider = new firebase.auth.FacebookAuthProvider();
        return from(this.afAuth.signInWithPopup(provider)).pipe(
            take(1),
            mergeMap(() => {
                return this.afAuth.authState.pipe(map(user => {
                    this.userSnapshot = user;
                    this.user.next(user);
                }))
            })
        );
    }

    updateUserWhenClaimsOutOfSync() {
        this.userSnapshot.getIdToken(true).then(_ => {
            this.afAuth.authState.pipe(take(1)).subscribe(user => {
                user.getIdTokenResult().then(res => {
                    this.userDb.userPlan = res.claims.userPlan;
                });
                this.userSnapshot = user;
                this.user.next(user);
            });
        });
    }

    isLoggedIn() {
        if (this.userSnapshot && this.userDb) {
            return of({ user: this.userSnapshot, userDb: this.userDb });
        } else {
            return this.afAuth.authState.pipe(
                take(1),
                mergeMap(user => forkJoin([
                    of(user),
                    user && user.uid ? this.dbUsersService.getByIdComplete(user.uid).pipe(
                        catchError((e) => {
                            return of(undefined);
                        })) : of(undefined),
                ])),
                map(result => {
                    const user: User = result[0];
                    const userDb: UserPrivate = result[1];

                    // sync the user with claims
                    if (userDb && user) {
                        user.getIdTokenResult().then(res => {
                            if (res.claims.userPlan !== userDb.userPlan) {
                                this.updateUserWhenClaimsOutOfSync();
                            }
                        });
                    }

                    if (user && user.uid) {
                        if (!userDb) {
                            this.router.navigate(['auth/agreements']);
                        } else if (
                            userDb.agreedToTermsAndConditionsVersion !== this.configService.configSnapshot.termsAndConditionsVersion ||
                            userDb.agreedToPrivacyPolicyVersion !== this.configService.configSnapshot.privacyPolicyVersion
                        ) {
                            this.userDb = userDb;
                            this.router.navigate(['auth/updated-agreements']);
                        }
                    }
                    this.userDb = userDb;
                    this.userSnapshot = user;
                    this.user.next(user);
                    return { user, userDb };
                })
            );
        }
    }

    logout() {
        this.afAuth.signOut().then(() => {
            this.user.next(undefined);
            this.userSnapshot = undefined;
        });
    }

    deleteAccount() {
        //mark for deletion in db
        return this.afAuth.authState.pipe(
            take(1),
            mergeMap(authState => {
                const deleteObs$ = from(authState.delete()).pipe(
                    map(s => {
                        this.comms.openSnackBar(`Account deleted successfully. You are logged out.`, 'Close', false);
                        this.userSnapshot = undefined;
                        this.userDb = undefined;
                        this.user.next(undefined);
                        this.router.navigate(['/']);
                    }),
                    catchError(err => {
                        this.comms.openSnackBar(`Deletion of the account has failed. Try to logout and login before starting this operation. Error message: ${err.message}`, 'Close', true);
                        return throwError(err);
                    })
                );
                return deleteObs$;
            }));
    }
}
