import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserPrivate } from '../../database/models/user';
import { userPlanEnum } from '../../database/models/user-plan.enum';
import { AuthorProjectsComponent } from './navigator-breadcrumb/author-projects/author-projects.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'firebase/auth';

@Component({
    selector: 'app-main-dashboard',
    templateUrl: './main-dashboard.component.html',
    styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {

    user: User;
    isLoggedOut: boolean;
    subscription: Subscription;
    publicUserName;
    userDb: UserPrivate;
    userPlanEnum = userPlanEnum;
    userPlan: string;

    isInApp: boolean;

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.router.events.subscribe((ev: NavigationEnd) => {
            if (ev instanceof NavigationEnd) {
                if (ev && ev.url && ev.url.includes('/app')) {
                    this.isInApp = true;
                } else if (ev && ev.url && !ev.url.includes('/app')) {
                    this.isInApp = false;
                }
            }
        });

        this.subscription = this.authService.user.subscribe(user => {
            this.user = user;
            if (!user) {
                this.isLoggedOut = true;
            } else {
                this.isLoggedOut = false;

                this.publicUserName = this.authService.userDb ? this.authService.userDb.publicUserName : this.user.displayName;
                this.userDb = this.authService.userDb;

                this.user.getIdTokenResult().then(res => {
                    this.userPlan = res.claims.userPlan as string;
                    // first time
                    if (this.userDb && res.claims.userPlan !== this.userDb.userPlan) {
                        user.getIdToken(true).then(_ => {
                            this.authService.updateUserWhenClaimsOutOfSync();
                        });
                    }

                });
            }
        });

        if (this.router.url === 'app') {

        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['auth/login']);
    }

    openMyProjectsDialog(event) {
        // event.stopPropagation();
        const dialogRef = this.dialog.open(AuthorProjectsComponent,
            {
                width: '800px',
                height: 'auto',
                maxHeight: 'calc(100vh - 20px)',
                autoFocus: false,
                panelClass: 'dark-panel',
                data: {
                }
            });
        return dialogRef.afterClosed();
    }
}
