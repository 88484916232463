<h1>Script {{script.title}}</h1>
<div class="row">
    <div class="col-12 col-sm-6 col-md-6 col-lg-4">
        <img class="script-image" [ngClass]="{picturePlaceholder: script.image.mid ? false : true}"
            [src]="script.image.mid ? script.image.mid : 'assets/image-placeholder.png'" />
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-8">
        <dl>
            <div>
                <dt>Type</dt>
                <dd>{{script.type}}</dd>
            </div>
            <div>
                <dt>Author</dt>
                <dd><a (click)="close()" matTooltip="See all public projects of this author" [routerLink]="'/projects/public/author/'+ script.authorPublicUserName">{{script.authorPublicUserName}}</a></dd>
            </div>
            <div>
                <dt>Date Created</dt>
                <dd>{{script.date.toDate() | date:'medium'}}</dd>
            </div>
            <div>
                <dt>Last Edit Date</dt>
                <dd>{{script.lastEditedDate.toDate() | date:'medium'}}</dd>
            </div>
        </dl>
    </div>
</div>