import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../../constants';
import { DatabaseService } from '../../database/database.service';
import { CommunicationService } from '../../shared/communication.service';

@Component({
    selector: 'app-project-create',
    templateUrl: './project-create.component.html',
    styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {

    formSubmitted = false;
    form = new FormGroup({
        title: new FormControl('', [Validators.required, Validators.maxLength(Constants.smallFieldLength)]),
        description: new FormControl(null, [Validators.maxLength(Constants.longFieldLength)]),
    });

    fileExtensionsConsistent = true;

    constructor(
        private readonly databaseService: DatabaseService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly comms: CommunicationService,
    ) { }

    ngOnInit(): void {
    }

    getErrorMessage(): string {
        let result = '';
        if (this.form.controls.title.hasError('required')) {
            result = 'You must enter a value';
        } else if (this.form.controls.title.hasError('maxlength')) {
            result = `Field contains more than ${Constants.smallFieldLength} characters`;
        }
        return result;
    }

    getErrorMessageDescription() {
        let result = '';
        if (this.form.controls.description.hasError('maxlength')) {
            result = `Field contains more than ${Constants.longFieldLength} characters`;
        }
        return result;
    }

    submit(): void {
        this.formSubmitted = true;

        if (this.form.valid) {
            this.comms.openSpinnerSnackBar(`Project is being created`);
            this.databaseService.projects.create(
                {
                    title: this.form.value.title,
                    description: this.form.value.description,
                    date: this.databaseService.serverTimestamp(),
                    image: {
                        min: '',
                        mid: '',
                        max: '',
                    },
                    lastEditedDate: this.databaseService.serverTimestamp()
                }
            ).subscribe(p => {
                this.formSubmitted = false;
                this.comms.openSnackBar(`Project '${this.form.value.title}' created successfully`, 'Close', false, Constants.snackbarTimer);
                this.router.navigate(['../'], {
                    relativeTo: this.route,
                });
            }, err => {
                this.comms.openSnackBar(`Error occured when creating the project. Error message: ${err.message}`, 'Close', true);
                this.formSubmitted = false;
            });
        } else {
            this.formSubmitted = false;
        }
    }
}
