import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { ConfigService } from '../../config.service';
import { Constants } from '../../constants';
import { DatabaseService } from '../../database/database.service';
import { Asset } from '../../database/models/asset';
import { Config } from '../../database/models/config';
import { Project } from '../../database/models/project';
import { projectVisibilityEnum } from '../../database/models/project-visibility.enum';
import { Script } from '../../database/models/script';
import { UserPrivate } from '../../database/models/user';
import { userPlanEnum } from '../../database/models/user-plan.enum';
import { UserProjectStats } from '../../database/models/user-project-stats';
import { CommunicationService } from '../../shared/communication.service';

@Component({
    selector: 'app-projects-overview',
    templateUrl: './projects-overview.component.html',
    styleUrls: ['./projects-overview.component.scss']
})
export class ProjectsOverviewComponent implements OnInit, OnDestroy {
    projects: Project[];
    activeProjectId: number;
    activeProjectTitle: string;
    projectsValueChange: Observable<any>;
    firstTime: boolean;
    publicUserName: string;
    projectVisibilityEnum = projectVisibilityEnum;
    pages: any[][] = [];
    pageSize = 5;
    currentPage = 1;
    maxPage = 0;

    assetMax: number;
    assetPercentage: number;

    userPlanEnum = userPlanEnum;
    currentUser: UserPrivate;
    stats: {
        privateCount: number,
        totalCount: number,
        totalAssetsSizeMb: number,
    } = { privateCount: undefined, totalCount: undefined, totalAssetsSizeMb: undefined };
    config: Config;

    form = new FormGroup({
        title: new FormControl(''),
    });

    lastVisibleDoc = undefined;

    userProjectStatsSubscription;
    currentProjectStatsSubscriptions = [];

    constructor(
        private readonly databaseService: DatabaseService,
        private readonly configService: ConfigService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: AngularFirestore,
        private readonly auth: AuthService,
        private readonly comms: CommunicationService,
        public readonly dialog: MatDialog,
        public readonly storage: AngularFireStorage,
    ) { }

    ngOnInit(): void {
        this.config = this.configService.configSnapshot;
        this.currentUser = this.auth.userDb;
        if(!this.currentUser.userPlan){
            this.auth.updateUserWhenClaimsOutOfSync();
        }

        this.assetMax = this.getMaxAllowedAssetForPlan();

        if (this.route.snapshot.queryParams['first'] === 'true') {
            this.firstTime = true;
            this.publicUserName = this.auth.userDb.publicUserName;
        }
        if (this.route.snapshot.queryParams['reload'] === 'true') {
            window.location.href = window.location.pathname + '?first=true';
        }

        this.userProjectStatsSubscription = this.store.collection(`users/${this.currentUser.uid}/project-stats`).doc('project-count').valueChanges().subscribe((stats: UserProjectStats) => {
            this.stats.privateCount = stats.privateCount;
            this.stats.totalCount = stats.totalCount;
            this.stats.totalAssetsSizeMb = stats.totalAssetsSizeMb;
            this.assetPercentage = this.stats.totalAssetsSizeMb / this.assetMax * 100;
            if (stats.totalCount > 0) {
                this.maxPage = Math.ceil(stats.totalCount / this.pageSize)
            }
        });

        this.getProjects(undefined);
    }

    ngOnDestroy() {
        if (this.userProjectStatsSubscription) {
            this.userProjectStatsSubscription.unsubscribe();
        }
        this.cleanCurrentProjectSubscriptions();
    }

    // when pageSize changes, pages array can be flattened and restructured to reflect that.
    // when pageNr changes, we fetch new if next is clicked and current PageCount is larger than max reached
    // when user edits, deletes or creates projects, paging should probably be reset to 0 situation or somehow adjusted as he is not looking anymore.
    getProjects(title) {
        if (this.pages.length < this.currentPage) {
            this.comms.openSpinnerSnackBar(`Loading the projects`);
            this.store.collection('projects', ref => {
                let basic;
                if (title) {
                    basic = ref.where('title', '>=', title).where('title', '<=', title + '\uf8ff')
                } else {
                    basic = ref;
                }
                basic = basic
                    .where('authorId', '==', this.auth.userSnapshot.uid)
                    .orderBy('lastEditedDate', 'desc')
                    .orderBy('title');

                if (this.pages.length > 0) {
                    const lastPage = this.pages[this.pages.length - 1];
                    const lastProjectDoc = lastPage[lastPage.length - 1];
                    basic = basic
                        .startAfter(lastProjectDoc)
                        .limit(this.pageSize);
                } else {
                    basic = basic.limit(this.pageSize);
                }
                return basic;
            }).get().pipe(take(1)).subscribe(p => {
                const projects = this.getProjectsFromDocs(p);
                if (projects.length === this.pageSize) {
                    this.projects = projects as any;
                    this.pages.push(p.docs);
                } else if (projects.length === 0) {
                    this.maxPage = this.currentPage - 1;
                    this.currentPage -= 1;
                } else if (projects.length < this.pageSize) {
                    this.projects = projects as any;
                    this.maxPage = this.currentPage;
                    this.pages.push(p.docs);
                }
                this.comms.openSnackBar(`Projects loaded.`, 'Close', false, Constants.snackbarShortTimer);
            }, err => {
                this.comms.openSnackBar(`Error happened when loading the proejcts. Error message: ${err.message}`, 'Close', true);
            });
        } else {
            this.projects = this.pages[this.currentPage - 1].map(d => this.getDataWithDocId(d));
        }
    }

    private getProjectsFromDocs(p) {
        return p.docs.map(d => this.getDataWithDocId(d));
    }

    private getDataWithDocId(d): any {
        return {
            id: d.id,
            ...d.data() as any[]
        };
    }

    projectNew(): void {
        this.router.navigate(['add'], { relativeTo: this.route });
    }

    projectEdit(id: string, event: Event): void {
        event.stopPropagation();
        this.router.navigate([id, 'edit'], { relativeTo: this.route });
    }

    projectDelete(project: Project, event: Event): void {

        event.stopPropagation();
        const dialogRef = this.dialog.open(DeleteProjectWarningComponent);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.comms.openSpinnerSnackBar(`Project '${project.title}' is being deleted`);

                this.databaseService.projects.deleteFb(project.id).subscribe(s => {

                    const newPages = [];
                    let flatPages = this.pages.flat();
                    flatPages = flatPages.filter(s => s.id !== project.id);
                    for (let i = 0; i < flatPages.length; i++) {
                        if (i % this.pageSize === 0) {
                            newPages.push([]);
                        }
                        const lastArray = newPages[newPages.length - 1];
                        lastArray.push(flatPages[i]);
                    }

                    this.pages = newPages;
                    if (this.currentPage > this.pages.length) {
                        this.currentPage = this.pages.length;
                    }

                    if (this.pages.length > 0) {
                        this.projects = this.pages[this.currentPage - 1].map(d => this.getDataWithDocId(d)) as any;
                    } else {
                        this.projects = [];
                    }

                    if (this.currentPage === 1 && this.pages.length === 1 && this.projects.length < this.pageSize) {
                        this.pages = [];
                        this.getProjects(undefined);
                    }

                    this.comms.openSnackBar(`Project '${project.title}' deleted successfully`, 'Close', false, Constants.snackbarTimer);
                });
            }
        });

    }

    nextPage() {
        this.currentPage += 1;
        this.getProjects(undefined)
    }

    previousPage() {
        this.currentPage -= 1;
        this.getProjects(undefined)
    }

    panelOpened(project: Project) {
        this.cleanCurrentProjectSubscriptions();

        if (!project.scripts) {
            project.scripts = undefined;
            // when user clicks to open the panel we can load in the scripts and assets for the project - depending on the users this might need to be limited to load only 5 scripts later on...
            this.databaseService.script.getScripts(project.id, this.auth.userSnapshot.uid).subscribe(res => {
                project.scripts = res;
            });
        }


        if (!project.assets) {
            project.assets = undefined;
            this.databaseService.assets.getAssets(project.id, this.auth.userSnapshot.uid).subscribe(res => {
                project.assets = res;
            });
        }

        const subscriptionStats = this.store.collection(`projects/${project.id}/project-stats`).doc('project-count').valueChanges().subscribe(stats => {
            project.stats = stats;
            if ((project.assets && project.stats.totalAssets > project.assets.length) || (!project.assets && project.stats.totalAssets > 0)) {
                this.refreshAssets(project);
            }
        }, err => {
            project.stats = { totalAssets: 0, totalScripts: 0 };
        });
        this.currentProjectStatsSubscriptions.push(subscriptionStats);
    }

    panelClosed(project: Project) {
        // this.cleanCurrentProjectSubscriptions();
    }

    refreshScripts(project: Project) {
        this.databaseService.script.getScripts(project.id, this.auth.userSnapshot.uid).subscribe(res => {
            project.scripts = res;
        });
    }

    refreshAssets(project: Project) {
        this.databaseService.assets.getAssets(project.id, this.auth.userSnapshot.uid).subscribe(res => {
            project.assets = res;
        });
    }

    scriptAdd(projectId: number): void {
        this.router.navigate([projectId, 'script', 'add'], { relativeTo: this.route });
    }

    scriptLaunch(script: Script): void {
        this.router.navigate([`/app/${script.authorPublicUserName}/${script.projectId}/${script.id}`]);
    }

    scriptEdit(projectId: number, scriptId: number): void {
        this.router.navigate([projectId, 'script', scriptId, 'edit'], { relativeTo: this.route });
    }

    scriptDelete(script: Script, project: Project): void {
        this.comms.openSpinnerSnackBar(`Removing script '${script.title}'`);
        this.databaseService.script.delete(script).subscribe(res => {
            // optimistic count update as this is done in cloud functions
            this.comms.openSnackBar(`Successfully removed script '${script.title}'.`, 'Close', false, Constants.snackbarTimer);
            project.scripts = project.scripts.filter(sc => sc.id !== script.id);
        },
        err => {
            this.comms.openSnackBar(`Error happened when removing script '${script.title}'. Try again later. Error message: ${err.message}`, 'Close', true);
        });
    }

    scriptImageAdd(script: Script) {
        this.router.navigate([script.projectId, 'script', script.id, 'image'], { relativeTo: this.route });
    }

    scriptImageDelete(script: Script) {
        // cleaning the project photo. We only update the script in firestore and keep the photos in storage.
        // If user decides to upload new photo then they will be overwritten. Otherwise they will remain in storage
        // till script gets deleted.
        this.comms.openSpinnerSnackBar(`Removing script '${script.title}' Photo`);
        this.databaseService.script.update({
            image: {
                min: '',
                mid: '',
                max: ''
            },
            lastEditedDate: this.databaseService.serverTimestamp()
        }, script.id, script.projectId).subscribe(res => {
            this.comms.openSnackBar(`Successfully removed script '${script.title}' Photo. Refresh page to see the effect. Photos help your project stand out, consider uploading a new one.`, 'Close', false, Constants.snackbarTimer);
        }, err => {
            this.comms.openSnackBar(`Error happened when removing script '${script.title}' Photo. Try again later. Error message: ${err.message}`, 'Close', true);
        });
    };

    assetAdd(projectId: number): void {
        this.router.navigate([projectId, 'assets', 'add'], { relativeTo: this.route });
    }

    assetDelete(asset: Asset, project: Project): void {
        this.databaseService.assets.delete(asset).subscribe(res => {
            // optimistic count update as this is done in cloud functions
            project.assets = project.assets.filter(as => as.id !== asset.id);
        });
    }

    imageAdd(project: Project) {
        this.router.navigate([project.id, 'image'], { relativeTo: this.route });
    }

    imageDelete(project: Project) {
        // cleaning the project photo. We only update the project in firestore and keep the photos in storage.
        // If user decides to upload new photo then they will be overwritten. Otherwise they will remain in storage
        // till project gets deleted.
        this.comms.openSpinnerSnackBar(`Removing project '${project.title}' Photo`);
        this.databaseService.projects.update({
            image: {
                min: '',
                mid: '',
                max: ''
            },
            lastEditedDate: this.databaseService.serverTimestamp()
        }, project.id).subscribe(res => {
            this.comms.openSnackBar(`Successfully removed project '${project.title}' Photo. Refresh page to see the effect. Photos help your project stand out, consider uploading a new one.`, 'Close', false, Constants.snackbarTimer);
        }, err => {
            this.comms.openSnackBar(`Error happened when removing project '${project.title}' Photo. Try again later. Error message: ${err.message}`, 'Close', true);
        });
    };

    private cleanCurrentProjectSubscriptions() {
        this.currentProjectStatsSubscriptions.forEach(subs => {
            subs.unsubscribe();
        });
        this.currentProjectStatsSubscriptions = [];
    }

    allowedToCreate() {
        let result = false;
        if (this.currentUser.userPlan === userPlanEnum.free) {
            result = (this.stats.privateCount < this.config.userPlanFreeMaxPrivateProjects &&
                this.stats.totalCount < this.config.userPlanFreeMaxTotalProjects);
        } else if (this.currentUser.userPlan === userPlanEnum.silver) {
            result = (this.stats.privateCount < this.config.userPlanSilverMaxPrivateProjects &&
                this.stats.totalCount < this.config.userPlanSilverMaxTotalProjects);
        } else if (this.currentUser.userPlan === userPlanEnum.gold) {
            result = (this.stats.privateCount < this.config.userPlanGoldMaxPrivateProjects &&
                this.stats.totalCount < this.config.userPlanGoldMaxTotalProjects);
        }
        return result;
    }


    allowedToCreateAsset(project: Project) {
        let result = false;
        if (this.currentUser.userPlan === userPlanEnum.free) {
            result = project.stats.totalAssets < this.config.userPlanFreeMaxAssetsPerProject;
        } else if (this.currentUser.userPlan === userPlanEnum.silver) {
            result = project.stats.totalAssets < this.config.userPlanSilverMaxAssetsPerProject;
        } else if (this.currentUser.userPlan === userPlanEnum.gold) {
            result = project.stats.totalAssets < this.config.userPlanGoldMaxAssetsPerProject;
        }
        return result;
    }

    getMaxAllowedAssetForPlan() {
        let result = 0;
        if (this.currentUser.userPlan === userPlanEnum.free) {
            result = this.config.userPlanFreeMaxTotalAssetsSizeMb;
        } else if (this.currentUser.userPlan === userPlanEnum.silver) {
            result = this.config.userPlanSilverMaxTotalAssetsSizeMb;
        } else if (this.currentUser.userPlan === userPlanEnum.gold) {
            result = this.config.userPlanGoldMaxTotalAssetsSizeMb;
        }
        return result;
    }

    allowedToCreateScript(project: Project) {
        let result = false;
        if (this.currentUser.userPlan === userPlanEnum.free) {
            result = project.stats.totalScripts < this.config.userPlanFreeMaxScriptsInProject;
        } else if (this.currentUser.userPlan === userPlanEnum.silver) {
            result = project.stats.totalScripts < this.config.userPlanSilverMaxScriptsInProject;
        } else if (this.currentUser.userPlan === userPlanEnum.gold) {
            result = project.stats.totalScripts < this.config.userPlanGoldMaxScriptsInProject;
        }
        return result;
    }

}

@Component({
    selector: 'delete-project-warning',
    templateUrl: 'delete-project-warning.html',
})
export class DeleteProjectWarningComponent { }
