import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-cookie-banner',
    templateUrl: './cookie-banner.component.html',
    styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

    constructor(
        public ref: MatDialogRef<CookieBannerComponent>
    ) { }

    ngOnInit() {
    }

    decline() {
        this.ref.close(false);
    }

    accept() {
        this.ref.close(true);
    }
}
