import { Injectable } from '@angular/core';
import { AssetsDb } from './assets.db';
import { ProjectsDb } from './projects.db';
import { SharedDbContextService } from './shared-db-context';
import { ScriptDb } from './script.db';
import { UsersDb } from './users.db';
import firebase from 'firebase/compat/app';
import { ConfigDb } from './config.db';

@Injectable()
export class DatabaseService {

    constructor(
        public readonly projects: ProjectsDb,
        public readonly users: UsersDb,
        public readonly assets: AssetsDb,
        public readonly script: ScriptDb,
        public readonly config: ConfigDb,
        private readonly sharedDbContext: SharedDbContextService,
    ) { }

    serverTimestamp() {
        return (firebase as any).firestore.FieldValue.serverTimestamp();
    };

}
