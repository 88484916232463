<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="bit-card">
                <ng-container *ngIf="termsAndConditionsVersion">
                    <h1>
                        Terms and Conditions version {{termsAndConditionsVersion}}
                    </h1>
                     <div [innerHTML]="termsAndConditionsHtml">
                    </div>
                </ng-container>
            </mat-card>
        </div>
    </div>
</div>