import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from '../../database/database.service';
import { projectVisibilityEnum } from '../../database/models/project-visibility.enum';

@Component({
    selector: 'app-public-projects-author-gallery',
    templateUrl: './public-projects-author-gallery.component.html',
    styleUrls: ['./public-projects-author-gallery.component.scss']
})
export class PublicProjectsAuthorGalleryComponent implements OnInit {

    publicUserNameParam = 'publicUserName'
    publicUserName: string;
    userId: string;
    visibility: projectVisibilityEnum;
    projectVisibilityEnum = projectVisibilityEnum;
    
    constructor(
        private readonly dbService: DatabaseService,
        private readonly route: ActivatedRoute
        ) { }

    ngOnInit() {
        this.publicUserName = this.route.snapshot.params[this.publicUserNameParam];
        this.visibility = this.route.snapshot.data.visibility;
        this.dbService.users.getPublicUserNameData(this.publicUserName).subscribe(publicUserName => {
            this.userId = publicUserName.uid;
        });
    }

}
