import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../../constants';
import { DatabaseService } from '../../database/database.service';
import { Project } from '../../database/models/project';
import { projectVisibilityEnum } from '../../database/models/project-visibility.enum';
import { CommunicationService } from '../../shared/communication.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AuthService } from '../../auth/auth.service';
import { userPlanEnum } from '../../database/models/user-plan.enum';

@Component({
    selector: 'app-project-edit',
    templateUrl: './project-edit.component.html',
    styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit {

    project: Project;
    projectIdParam = 'projectId';
    projectId;
    formSubmitted = false;

    form: FormGroup;
    projectVisibilityEnum = projectVisibilityEnum;
    userPlan: userPlanEnum;
    userPlanEnum = userPlanEnum;

    constructor(
        private readonly databaseService: DatabaseService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly comms: CommunicationService,
        private readonly storage: AngularFireStorage,
        private readonly auth: AuthService
    ) { }

    ngOnInit(): void {
        this.projectId = this.route.snapshot.params[this.projectIdParam];
        this.databaseService.projects.getByIdFb(this.projectId).subscribe(p => {
            this.project = p;
            this.form = new FormGroup({
                title: new FormControl(p.title, [Validators.required, Validators.maxLength(Constants.smallFieldLength)]),
                description: new FormControl(p.description, [Validators.maxLength(Constants.longFieldLength)]),
                visibility: new FormControl(p.visibility),
            });
        });
        this.userPlan = this.auth.userDb.userPlan;
    }

    getErrorMessage(): string {
        let result = '';
        if (this.form.controls.title.hasError('required')) {
            result = 'You must enter a value';
        } else if (this.form.controls.title.hasError('maxlength')) {
            result = `Field contains more than ${Constants.smallFieldLength} characters`;
        }
        return result;
    }

    getErrorMessageDescription() {
        let result = '';
        if (this.form.controls.description.hasError('maxlength')) {
            result = `Field contains more than ${Constants.longFieldLength} characters`;
        }
        return result;
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmitted = true;
            this.comms.openSpinnerSnackBar(`Project is being updated`);
            this.databaseService.projects.update(
                {
                    title: this.form.value.title,
                    description: this.form.value.description,
                    lastEditedDate: this.databaseService.serverTimestamp(),
                    visibility: this.form.value.visibility,
                }, this.projectId
            ).subscribe(s => {
                this.formSubmitted = false;
                this.comms.openSnackBar(`Project '${this.form.value.title}' updated successfully`, 'Close', false, Constants.snackbarTimer);
                this.router.navigate(['../../'], {
                    relativeTo: this.route,
                });
            }, err => {
                this.comms.openSnackBar(`Error occured when updating the project. Error message: ${err.message}`, 'Close', true);
                this.formSubmitted = false;
            });
        } else {
            this.formSubmitted = false;
        }
    }
}
