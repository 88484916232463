import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { ImageUploadCropperComponent } from '../shared/image-upload-cropper/image-upload-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    declarations: [
        ImageUploadCropperComponent,
    ],
    exports: [
        ImageUploadCropperComponent,
    ],
    imports: [
        CommonModule,
        ImageCropperModule,
        MaterialModule
    ],
})
export class SharedModule { }
