<span>
    <ng-container *ngIf="currentUser.userPlan === userPlanEnum.free">
        <a routerLink="/auth/pick-plan" class="quota-breach" *ngIf="stats.totalAssets >= config.userPlanFreeMaxAssetsPerProject">
            {{currentUser.userPlan.toUpperCase()}} plan limit - {{stats.totalAssets}} out of
            {{config.userPlanFreeMaxAssetsPerProject}} allowed.
        </a>
        <ng-container *ngIf="stats.totalAssets < config.userPlanFreeMaxAssetsPerProject">
            {{stats.totalAssets}} out of {{config.userPlanFreeMaxAssetsPerProject}} allowed.
        </ng-container>
    </ng-container>
    <ng-container *ngIf="currentUser.userPlan === userPlanEnum.silver">
        <a routerLink="/auth/pick-plan" class="quota-breach" *ngIf="stats.totalAssets >= config.userPlanSilverMaxAssetsPerProject">
            {{currentUser.userPlan.toUpperCase()}} plan limit - {{stats.totalAssets}} out of
            {{config.userPlanSilverMaxAssetsPerProject}} allowed.
        </a>
        <ng-container *ngIf="stats.totalAssets < config.userPlanSilverMaxAssetsPerProject">
            {{stats.totalAssets}} out of {{config.userPlanSilverMaxAssetsPerProject}} allowed.
        </ng-container>
    </ng-container>
    <ng-container *ngIf="currentUser.userPlan === userPlanEnum.gold">
        <a routerLink="/auth/pick-plan" class="quota-breach" *ngIf="stats.totalAssets >= config.userPlanGoldMaxAssetsPerProject">
            {{currentUser.userPlan.toUpperCase()}} plan limit - {{stats.totalAssets}} out of
            {{config.userPlanGoldMaxAssetsPerProject}} allowed.
        </a>
        <ng-container *ngIf="stats.totalAssets < config.userPlanGoldMaxAssetsPerProject">
            {{stats.totalAssets}} out of {{config.userPlanGoldMaxAssetsPerProject}} allowed.
        </ng-container>
    </ng-container>
</span>