import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DatabaseService } from '../../../database/database.service';
import { Project } from '../../../database/models/project';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ConfigService } from '../../../config.service';
import { AuthService } from '../../../auth/auth.service';
import { userPlanEnum } from '../../../database/models/user-plan.enum';
import { Config } from '../../../database/models/config';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { CommunicationService } from '../../../shared/communication.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Constants } from '../../../constants';

@Component({
    selector: 'app-asset-add',
    templateUrl: './asset-add.component.html',
    styleUrls: ['./asset-add.component.scss']
})
export class AssetAddComponent implements OnInit, OnDestroy {

    project: Project;
    projectId: string;
    projectIdParam = 'projectId';

    currentAssetFile: File;
    filesFinishedReading = new Subject<File>();
    userPlan: userPlanEnum;
    userPlanEnum = userPlanEnum;

    formSubmitted = false;
    color: ThemePalette = 'primary';
    mode: ProgressBarMode = 'determinate';

    uploadPercentage = 0;
    bufferValue = 100;

    form = new FormGroup({
        title: new FormControl('', [Validators.required, Validators.maxLength(Constants.smallFieldLength)]),
    });

    fileSizeGood = true;
    fileNameGood = true;

    config: Config;

    constructor(
        private readonly databaseService: DatabaseService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly auth: AuthService,
        private readonly configService: ConfigService,
        private readonly comms: CommunicationService,
        private readonly storage: AngularFireStorage,
        private readonly store: AngularFirestore
    ) { }

    ngOnInit(): void {
        this.config = this.configService.configSnapshot;
        this.userPlan = this.auth.userDb.userPlan;
        this.projectId = this.route.snapshot.params[this.projectIdParam]
        this.databaseService.projects.getByIdFb(this.projectId).subscribe((p) => {
            this.project = p;

            setTimeout(() => {
                const inputFileElement = document.getElementById('assetFileUpload') as HTMLInputElement;
                inputFileElement.onchange = (e) => {
                    const files = inputFileElement.files;

                    for (let i = 0; i < files.length; i++) {
                        const file = files[i];
                        if (file) {
                            this.currentAssetFile = file;
                        }
                    }
                    this.filesFinishedReading.next(this.currentAssetFile);
                };
            });
        });

        this.filesFinishedReading.subscribe(files => {
            this.fileSizeGood = this.fileSizeValidator(this.currentAssetFile);
            this.fileNameGood = this.fileNameValid(this.currentAssetFile)
        });
    }

    ngOnDestroy(): void {
        this.filesFinishedReading.complete();
    }

    getErrorMessage(): string {
        let result = '';
        if (this.form.controls.title.hasError('required')) {
            result = 'You must enter a value';
        } else if (this.form.controls.title.hasError('maxlength')) {
            result = `Field contains more than ${Constants.smallFieldLength} characters`;
        }
        return result;
    }

    submit(): void {
        this.formSubmitted = true;
        if (this.form.valid && this.currentAssetFile && this.fileSizeGood && this.fileNameGood) {

            this.comms.openSpinnerSnackBar('Uploading asset file');
            const path = `users/${this.auth.userSnapshot.uid}/projects/${this.projectId}/assets/${this.currentAssetFile.name}`;
            const uploadTask: AngularFireUploadTask = this.storage.upload(
                path,
                this.currentAssetFile,
                {                    
                    cacheControl: 'public,max-age=2592000',
                    customMetadata: {
                        'visibility': this.project.visibility,
                        'uid': this.auth.userSnapshot.uid,
                        'authorPublicUserName': this.auth.userDb.publicUserName,
                        'projectId': this.projectId,
                        'fileType': 'projectAsset',
                        'title': this.form.value.title,
                    }
                }
            );

            uploadTask.percentageChanges().subscribe(percentage => {
                this.uploadPercentage = percentage;
            });
            uploadTask.then(snapshot => {
                // try getting asset from the database and update download url
                this.comms.openSnackBar(`Asset uploaded successfully. It may take a few seconds for asset to appear under your project.`, 'Close', false, Constants.snackbarTimer);
                this.navigateBack();
            }, err => {
                this.formSubmitted = false;
                this.comms.openSnackBar(`Something went wrong when uploading an asset. You may not have permissions or the file with the same name has already been uploaded.`, 'Close', true);
            });
        }
    }

    navigateBack() {
        this.formSubmitted = false;
        this.router.navigate(['../../../'], {
            relativeTo: this.route,
        });
    }

    fileNameValid(assetFile: File) {
        const regex = new RegExp('^[A-Za-z0-9 _()-]*[.][A-Za-z0-9]{3,4}$');
        return regex.test(assetFile.name);
    }

    fileSizeValidator(assetFile: File): boolean {
        let sizeInLimitsForPlan = false;
        if (this.userPlan === userPlanEnum.free && this.mb(assetFile.size) < this.configService.configSnapshot.userPlanFreeMaxSingleAssetsSizeMb) {
            sizeInLimitsForPlan = true;
        } else if (this.userPlan === userPlanEnum.silver && this.mb(assetFile.size) < this.configService.configSnapshot.userPlanSilverMaxSingleAssetsSizeMb) {
            sizeInLimitsForPlan = true;
        } else if (this.userPlan === userPlanEnum.gold && this.mb(assetFile.size) < this.configService.configSnapshot.userPlanGoldMaxSingleAssetsSizeMb) {
            sizeInLimitsForPlan = true;
        }
        return sizeInLimitsForPlan;
    }

    mb(bytes: number): number {
        return (bytes / 1024 / 1024);
    }

    mbString(bytes: number): string {
        return (bytes / 1024 / 1024).toFixed(3);
    }

}
