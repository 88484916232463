<ng-container *ngIf="user && userDb">
        <img [src]="user.photoURL" class="profile-picture" alt="Profile picture" />
        <dl>
            <dt>
                Public User Name
            </dt>
            <dd>
                {{userDb.publicUserName}}
            </dd>
            <dt>
                Auth Service Display Name
            </dt>
            <dd>
                {{user.displayName}}
            </dd>
            <dt>
                Email
            </dt>
            <dd>
                {{user.email}}
            </dd>
            <dt>
                Plan
            </dt>
            <dd>
                {{userDb.userPlan}}
            </dd>
            <dt>
                Plan Start Date
            </dt>
            <dd>
                {{userDb.userPlanActivatedDate.toDate() | date:'medium'}}
            </dd>
            <dt>
                Plan Expiration Date
            </dt>
            <dd>
                {{planExpirationDate | date:'medium'}}
            </dd>
            <dt>
                Agreed to Terms and Conditions version
            </dt>
            <dd>
                {{userDb.agreedToTermsAndConditionsVersion}}
            </dd>
            <dt>
                Agreed to Privacy Policy version
            </dt>
            <dd>
                {{userDb.agreedToPrivacyPolicyVersion}}
            </dd>
            <dt>
                Agreed to receive emails
            </dt>
            <dd>
                {{userDb.agreedToReceiveEmails ? 'yes' : 'no'}}
            </dd>
            <dt>
                18 years old or older
            </dt>
            <dd>
                {{userDb.isAdult ? 'yes' : 'no'}}
            </dd>
            <dt>
                Last login date
            </dt>
            <dd>
                {{userDb.lastLoginDate.toDate() | date:'medium'}}
            </dd>
        </dl>
        <button type="button" mat-button mat-raised-button (click)="editProfile()">Edit profile</button>
        <button type="button" mat-button (click)="deleteAccount()">Delete my Account</button>
</ng-container>