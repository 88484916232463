import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserPrivate, UserPublic } from '../../database/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'firebase/auth';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    user: User;
    userDb: UserPrivate & UserPublic;
    
    planExpirationDate: Date;

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.user = this.authService.userSnapshot;
        this.userDb = this.authService.userDb;

        const planActivated = this.userDb.userPlanActivatedDate.toDate();
        this.planExpirationDate = planActivated.setDate(planActivated.getDate() + this.userDb.userPlanExpiresInDays);
    }

    ngOnDestroy() {
        
    }

    deleteAccount(){
        this.router.navigate(['../user-deletion-steps'], {relativeTo: this.route});
    }

    editProfile(){
        this.router.navigate(['../profile-edit'], {relativeTo: this.route});
    }

}
