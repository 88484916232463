import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Config } from '../../database/models/config';

@Component({
    selector: 'app-pick-plan',
    templateUrl: './pick-plan.component.html',
    styleUrls: ['./pick-plan.component.scss']
})
export class PickPlanComponent implements OnInit {

    config: Config;

    constructor(
        private readonly configService: ConfigService
    ) { }

    ngOnInit() {
        this.config = this.configService.configSnapshot;
    }

}
