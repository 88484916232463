import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { ConfigService } from '../../config.service';
import { Constants } from '../../constants';
import { DatabaseService } from '../../database/database.service';
import { CommunicationService } from '../../shared/communication.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-updated-agreements',
    templateUrl: './updated-agreements.component.html',
    styleUrls: ['./updated-agreements.component.scss']
})
export class UpdatedAgreementsComponent implements OnInit {

    termsChanged: boolean;
    privacyPolicyChanged: boolean;

    form;

    formSubmitted = false;

    constructor(
        private readonly authService: AuthService,
        private readonly configService: ConfigService,
        private readonly store: AngularFirestore,
        private readonly dbService: DatabaseService,
        private readonly router: Router,
        private readonly comms: CommunicationService,
        private readonly route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.privacyPolicyChanged = this.authService.userDb.agreedToPrivacyPolicyVersion !== this.configService.configSnapshot.privacyPolicyVersion;
        this.termsChanged = this.authService.userDb.agreedToTermsAndConditionsVersion !== this.configService.configSnapshot.termsAndConditionsVersion;

        const formModel = {} as any;
        if (this.privacyPolicyChanged) {
            formModel.privacyPolicy = new FormControl(false, [Validators.requiredTrue])
        }
        if (this.termsChanged) {
            formModel.termsAndConditions = new FormControl(false, [Validators.requiredTrue])
        }
        this.form = new FormGroup(formModel);
    }

    submit(): void {
        if (this.form.valid) {
            this.comms.openSpinnerSnackBar(`Updating agreements.`);

            const refPrivateUser = this.store.firestore.collection(`users/${this.authService.userDb.uid}/private`).doc(`${this.authService.userDb.uid}`);
            let update;
            if (this.termsChanged && this.privacyPolicyChanged) {
                update = from(refPrivateUser.update({
                    agreedToTermsAndConditionsDate: this.dbService.serverTimestamp(),
                    agreedToTermsAndConditionsVersion: this.configService.configSnapshot.termsAndConditionsVersion,
                    agreedToPrivacyPolicyDate: this.dbService.serverTimestamp(),
                    agreedToPrivacyPolicyVersion: this.configService.configSnapshot.privacyPolicyVersion,
                }));
            } else if (this.termsChanged) {
                update = from(refPrivateUser.update({
                    agreedToTermsAndConditionsDate: this.dbService.serverTimestamp(),
                    agreedToTermsAndConditionsVersion: this.configService.configSnapshot.termsAndConditionsVersion,
                }));
            } else if (this.privacyPolicyChanged) {
                update = from(refPrivateUser.update({
                    agreedToPrivacyPolicyDate: this.dbService.serverTimestamp(),
                    agreedToPrivacyPolicyVersion: this.configService.configSnapshot.privacyPolicyVersion,
                }));
            }
            update.subscribe(res => {
                this.authService.userDb.agreedToPrivacyPolicyVersion = this.configService.configSnapshot.privacyPolicyVersion;
                this.authService.userDb.agreedToTermsAndConditionsVersion = this.configService.configSnapshot.termsAndConditionsVersion;
                this.comms.openSnackBar(`Agreement information successfully updated`, 'Close', false, Constants.snackbarTimer);
                this.router.navigate(['../../projects/my'], {
                    relativeTo: this.route,
                });
            });
        }
    }

    deleteAccount() {
        this.router.navigate(['/auth/user-deletion-steps']);
    }
}
