import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { AssetsDb } from './assets.db';
import { Asset } from './models/asset';
import { Project } from './models/project';
import { projectVisibilityEnum } from './models/project-visibility.enum';
import { Script } from './models/script';
import { SharedDbContextService } from './shared-db-context';
import { ScriptDb } from './script.db';
import { UserProjectStats } from './models/user-project-stats';
import { ProjectStats } from './models/project-stats';

@Injectable()
export class ProjectsDb {

    constructor(
        private readonly sharedDbContext: SharedDbContextService,
        private readonly assets: AssetsDb,
        private readonly store: AngularFirestore,
        private readonly auth: AuthService
    ) { }

    getByIdFb(id: string): Observable<Project> {
        return this.sharedDbContext.getByIdFb(id, 'projects');
    }

    create(project: Project): Observable<void> {
        project.authorId = this.auth.userSnapshot.uid;
        project.authorPublicUserName = this.auth.userDb.publicUserName;
        project.visibility = projectVisibilityEnum.private;
        const ref = this.store.firestore.collection('projects').doc()
        return from(ref.set(project));
    }

    getUserProjectStats(uid: string): Observable<UserProjectStats> {
        return this.store.collection(`users/${uid}/project-stats`).doc('project-count').get().pipe(take(1), map(r => r.data()));
    }

    getProjectStats(projectId: string): Observable<ProjectStats> {
        return this.store.collection(`projects/${projectId}/project-stats`).doc('project-count').get().pipe(take(1), map(r => r.data()));
    }

    update(project: Project, id): Observable<void> {
        project.authorId = this.auth.userSnapshot.uid;
        const ref = this.store.firestore.collection('projects').doc(id)
        return from(ref.update(project));
    }

    deleteFb(id: string): Observable<void> {
        const ref = this.store.firestore.collection('projects').doc(id)
        return from(ref.delete());
    }
}
