import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { ConfigService } from '../../../config.service';
import { Constants } from '../../../constants';
import { DatabaseService } from '../../../database/database.service';
import { Project } from '../../../database/models/project';
import { scriptTypesEnum } from '../../../database/models/script-types.enum';
import { CommunicationService } from '../../../shared/communication.service';

@Component({
    selector: 'app-script-add',
    templateUrl: './script-add.component.html',
    styleUrls: ['./script-add.component.scss']
})
export class ScriptAddComponent implements OnInit {

    project: Project;
    projectIdParam = 'projectId';
    projectId: string;
    scriptTypesEnum = scriptTypesEnum;
    formSubmitted = false;

    form = new FormGroup({
        title: new FormControl('', [Validators.required, Validators.maxLength(Constants.smallFieldLength)]),
        type: new FormControl(scriptTypesEnum.typescript),
    });

    fileExtensionsConsistent = true;

    constructor(
        private readonly databaseService: DatabaseService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly config: ConfigService,
        private readonly auth: AuthService,
        private readonly comms: CommunicationService,
    ) { }

    ngOnInit(): void {
        this.projectId = this.route.snapshot.params[this.projectIdParam];
        this.databaseService.projects.getByIdFb(this.projectId).subscribe((p) => {
            this.project = p;
        });
    }

    getErrorMessage(): string {
        let result = '';
        if (this.form.controls.title.hasError('required')) {
            result = 'You must enter a value';
        } else if (this.form.controls.title.hasError('maxlength')) {
            result = `Field contains more than ${Constants.smallFieldLength} characters`;
        }
        return result;
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmitted = true;
            this.comms.openSpinnerSnackBar(`Script is being created`);
            this.databaseService.script.create(
                {
                    projectId: this.projectId,
                    authorId: this.auth.userDb.uid,
                    authorPublicUserName: this.auth.userDb.publicUserName,
                    appVersion: this.config.configSnapshot.appVersion,
                    title: this.form.value.title,
                    type: this.form.value.type,
                    image: {
                        min: '',
                        mid: '',
                        max: ''
                    },
                    script: '',
                    date: this.databaseService.serverTimestamp(),
                    lastEditedDate: this.databaseService.serverTimestamp(),
                }
            ).subscribe(_ => {
                this.formSubmitted = false;
                this.comms.openSnackBar(`Script created successfully`, 'Close', false, Constants.snackbarTimer);
                this.router.navigate(['../../../'], {
                    relativeTo: this.route,
                });
            }, err => {
                this.comms.openSnackBar(`Error occured when creating the script. Error message: ${err.message}`, 'Close', true);
                this.formSubmitted = false;
            });
        } else {
            this.formSubmitted = false;
        }
    }
}
