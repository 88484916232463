import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from '../auth.service';
import { ConfigService } from "../../config.service";

@Injectable()
export class UpdatedAgreementsSignedGuard implements CanActivate {

    constructor(
        private readonly authService: AuthService,
        private readonly configService: ConfigService,
        private readonly router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const result = this.authService.userDb && (this.authService.userDb.agreedToPrivacyPolicyVersion !== this.configService.configSnapshot.privacyPolicyVersion ||
            this.authService.userDb.agreedToTermsAndConditionsVersion !== this.configService.configSnapshot.termsAndConditionsVersion);
        if (result) {
            this.router.navigate(['/auth/updated-agreements']);
        }
        return !result;
    }
}