<h2>Create New Project</h2>
<form *ngIf="form" [formGroup]="form" (submit)="submit()">
    <div>
        <mat-form-field color="accent" appearance="fill">
            <mat-label>Title</mat-label>
            <input color="accent" required matInput formControlName="title">
            <mat-error *ngIf="form.controls['title'].invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" appearance="fill">
            <mat-label>Description</mat-label>
            <textarea color="accent" matInput formControlName="description"></textarea>
            <mat-error *ngIf="form.controls['description'].invalid">{{getErrorMessageDescription()}}</mat-error>
        </mat-form-field>
    </div>
    <button *ngIf="!formSubmitted" class="form-submit" mat-button mat-primary-button>Submit</button>
</form>