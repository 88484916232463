import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route, UrlSegment, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfigGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private readonly configService: ConfigService) { }
   
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.configService.getConfig().pipe(map(conf => conf ? true : false));
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.configService.getConfig().pipe(map(conf => conf ? true : false));
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this.configService.getConfig().pipe(map(conf => conf ? true : false));
    }

}