<div class="container">
    <div class="row">
        <div class="col">
            <mat-card class="bit-card">
                <ng-container *ngIf="privacyPolicyVersion">
                    <h1>
                        Privacy Policy version {{privacyPolicyVersion}}
                    </h1>
                     <div [innerHTML]="privacyPolicyHtml">
                    </div>
                </ng-container>
            </mat-card>
        </div>
    </div>
</div>