import { Component, Input, OnInit } from '@angular/core';
import { Config } from '../../../database/models/config';
import { ProjectStats } from '../../../database/models/project-stats';
import { UserPrivate } from '../../../database/models/user';
import { userPlanEnum } from '../../../database/models/user-plan.enum';

@Component({
    selector: 'app-asset-quota',
    templateUrl: './asset-quota.component.html',
    styleUrls: ['./asset-quota.component.scss']
})
export class AssetQuotaComponent implements OnInit {
    @Input()
    currentUser: UserPrivate;

    @Input()
    stats: ProjectStats;

    @Input()
    config: Config;
    
    userPlanEnum = userPlanEnum;

    constructor() { }

    ngOnInit() {
    }

}
