<ng-container *ngIf="publicUserName">
    <h2>
        <ng-container *ngIf="visibility === projectVisibilityEnum.public">Public Projects</ng-container>
        <ng-container *ngIf="visibility === projectVisibilityEnum.goldCommunity">Gold Community Projects</ng-container>
        <ng-container *ngIf="visibility === projectVisibilityEnum.silverCommunity">Silver Community Projects
        </ng-container>
        Created by <span class="public-user-name">{{publicUserName}}</span>
    </h2>
    <app-projects-gallery *ngIf="userId" [visibility]="visibility" [authorId]="userId" [pageSize]="8">
    </app-projects-gallery>
</ng-container>