import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { BusySnackbarComponent } from './busy-snackbar/busy-snackbar.component';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';

@Injectable({ providedIn: 'root' })
export class CommunicationService {

    constructor(
        private readonly dialog: MatDialog,
        private _snackBar: MatSnackBar
    ) { }

    openSnackBar(message: string, action: string, error: boolean, duration?: number): MatSnackBarRef<any> {
        return this._snackBar.open(message, action, { duration, panelClass: error ? 'snackbar-error' : 'snackbar-success' });
    }

    openSpinnerSnackBar(message: string, duration?: number): MatSnackBarRef<any> {
        return this._snackBar.openFromComponent(BusySnackbarComponent, { data: { message }, duration, panelClass: 'snackbar-busy' });
    }

    openCookieBanner(): Observable<any> {
        const dialogRef = this.dialog.open(CookieBannerComponent,
            {
                width: '600px',
                height: 'auto',
                maxHeight: 'calc(100vh - 20px)',
                position: {
                    bottom: '10px'
                },
                hasBackdrop: true,
                autoFocus: false,
                disableClose: true,
            });
        return dialogRef.afterClosed();
    }
}
