import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from '../../database/database.service';
import { Project } from '../../database/models/project';
import { projectVisibilityEnum } from '../../database/models/project-visibility.enum';
import { Script } from '../../database/models/script';
import { userPlanEnum } from '../../database/models/user-plan.enum';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {

    @Input()
    inModal: boolean;
    @Input()
    modalProject: Project;
    @Output()
    openedScript = new EventEmitter<Script>();

    project: Project;
    projectIdParam = 'projectId';
    projectId;

    visibility: projectVisibilityEnum;
    projectVisibilityEnum = projectVisibilityEnum;

    userPlan: userPlanEnum;
    userPlanEnum = userPlanEnum;

    
    constructor(
        private readonly databaseService: DatabaseService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
    ) { }

    ngOnInit(): void {
        if(!this.inModal){
            this.visibility = this.route.snapshot.data.visibility;
            this.projectId = this.route.snapshot.params[this.projectIdParam];
            this.databaseService.projects.getByIdFb(this.projectId).subscribe(p => {
                this.project = p;
                this.databaseService.script.getScripts(this.projectId, this.project.authorId).subscribe(scripts => {
                    this.project.scripts = scripts;
                });
            });
        }
        else {
            this.project = this.modalProject;
            this.projectId = this.modalProject.id;
            this.databaseService.script.getScripts(this.project.id, this.project.authorId).subscribe(scripts => {
                this.project.scripts = scripts;
            });
        }
        
    }

    scriptLaunch(script: Script){
        if(this.inModal){
            this.openedScript.emit(script);
        }
        this.router.navigate([`/app/${script.authorPublicUserName}/${script.projectId}/${script.id}`]);
    }

    getRouteFromVisibility(visibility: projectVisibilityEnum){
        let route = '';
        if(visibility === projectVisibilityEnum.public){
            route = 'public';
        } else if(visibility === projectVisibilityEnum.silverCommunity){
            route = 'silver';
        } else if(visibility === projectVisibilityEnum.goldCommunity){
            route = 'gold';
        }
        return route;
    }
}
