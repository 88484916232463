<div>
    <span>
        This site uses Cookies to track your use of this website with Google Analytics. You can read more about how we use Cookies in our Cookies Policy and how we use your data under Privacy Policy.
    </span>
    <div>
        <a routerLink="/cookies-policy">Cookies Policy</a> | 
        <a routerLink="/privacy-policy">Privacy Policy</a>
    </div>
    <div>
        <button mat-button (click)="accept()">Accept</button>
        <button mat-button (click)="decline()">Decline</button>
    </div>
</div>