<div *ngIf="projectStats && projectStats.totalCount > 0">
    <mat-icon>report_problem</mat-icon>
    <h2>
        {{projectStats.totalCount}} Projects.
    </h2>
    To be sure your data is removed completely we ask you to first remove all of your projects. Be aware that after your projects are removed there will be no way to restore them.
    <button *ngIf="!batchInitialised" mat-button (click)="batchDeleteProjects()">Initiate Batch Delete on All
        Projects</button>
    <mat-spinner diameter="30" *ngIf="batchInitialised"></mat-spinner><span *ngIf="batchInitialised">Projects are being removed</span>
</div>
<div *ngIf="projectStats && projectStats.totalCount === 0">
    <mat-icon>report_problem</mat-icon>
    <h2>
        All of your projects were removed
    </h2>
    <p>
        We will miss you! Let us know how we can make Bit by bit developers community better for you. Our email is <a href="mailto:info@bitbybit.dev">info@bitbybit.dev</a>.
    </p>
    <p>
        To finish the removal of your account you may need to logout and login again.
    </p>
    <button mat-button (click)="deleteAccount()">Delete my Account</button>
</div>