import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { DatabaseModule } from './database/database.module';
import { AuthModule } from './auth/auth.module';
import 'firebase/auth';
import { MainDashboardComponent } from './auth/main-dashboard/main-dashboard.component';
import { MaterialModule } from './material.module';
import { ConfigGuard } from './projects/config.guard';
import { ConfigResolver } from './config-resolver';
import { BusySnackbarComponent } from './shared/busy-snackbar/busy-snackbar.component';
import { FileSizePipe } from './shared/file-size.pipe';
import { NavigatorBreadcrumbComponent } from './auth/main-dashboard/navigator-breadcrumb/navigator-breadcrumb.component';
import { BitbybitSService } from './bitbybit-s/bitbybit-s.service';
import { AuthorProjectsComponent } from './auth/main-dashboard/navigator-breadcrumb/author-projects/author-projects.component';
import { ProjectScriptsComponent } from './auth/main-dashboard/navigator-breadcrumb/project-scripts/project-scripts.component';
import { ScriptDetailsComponent } from './auth/main-dashboard/navigator-breadcrumb/script-details/script-details.component';
import { ProjectsModule } from './projects/projects.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AnalyticsService } from './analytics.service';
import { CookieBannerComponent } from './shared/cookie-banner/cookie-banner.component';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { ORIGIN as FUNCTIONS_ORIGIN } from '@angular/fire/compat/functions';

@NgModule({
    declarations: [
        AppComponent,
        MainDashboardComponent,
        NavigatorBreadcrumbComponent,
        BusySnackbarComponent,
        CookieBannerComponent,
        AuthorProjectsComponent,
        ProjectScriptsComponent,
        ScriptDetailsComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule,
        DatabaseModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        AppRoutingModule,
        ProjectsModule,
        AuthModule,
        MaterialModule,
        ImageCropperModule,
    ],
    providers: [AuthService, ConfigGuard, ConfigResolver, FileSizePipe, BitbybitSService, AnalyticsService,
        { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:4005'] : undefined },
        { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 4010] : undefined },
        { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:4006'] : undefined },
        { provide: USE_STORAGE_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:4009'] : undefined },
        { provide: FUNCTIONS_ORIGIN, useFactory: () => environment.useEmulators ? 'http://localhost:4006' : undefined },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
