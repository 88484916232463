<div class="container projects">
    <div class="row">
        <div class="col col-sm-1"></div>
        <div class="col-12 col-sm-10">
            <div class="panel">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="col col-sm-1"></div>
    </div>
</div>