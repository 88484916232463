<div class="image-upload" *ngIf="maxRes">
    <input type="file" accept=".png, .jpg, .jpeg" (change)="fileChangeEvent($event)" />
    <div class="row">
        <div class="col-12 col-sm-8">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [aspectRatio]="aspectRatio" format="png" [cropperMinWidth]="this.maxRes.width"
                [cropperMinHeight]="this.maxRes.height" (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)" [resizeToWidth]="this.maxRes.width"
                [resizeToHeight]="this.maxRes.height" (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>
        <div class="col-12 col-sm-4">
            <img *ngIf="croppedImage" [src]="croppedImage" />
        </div>
    </div>
    <button *ngIf="croppedImage" mat-button (click)="accept()">Accept</button>
</div>