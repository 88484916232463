import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { Asset } from './models/asset';
import { SharedDbContextService } from './shared-db-context';

@Injectable()
export class AssetsDb {

    constructor(
        private readonly sharedDbContext: SharedDbContextService,
        private readonly store: AngularFirestore,
        private readonly storage: AngularFireStorage,
    ) { }

    // when getting assets we check if they all have download URL's
    // This is done to make sure that there were no unfinished business when uploading assets and download URLS are present on all assets before they are given to UI.
    getAssets(projectId, authorId): Observable<Asset[]> {
        const ref = this.store.collection(`projects/${projectId}/assets`, ref => ref.where('authorId', '==', authorId));
        return ref.get().pipe(
            take(1),
            mergeMap(res => {
                const observables = res.docs.map(assetDoc => {
                    const data: Asset = assetDoc.data();
                    if (!data.downloadUrl) {
                        return this.storage.ref(data.path).getDownloadURL().pipe(
                            take(1),
                            mergeMap(url => {
                                return from(assetDoc.ref.update(
                                    {
                                        downloadUrl: url,
                                        lastEditedDate: this.sharedDbContext.serverTimestamp()
                                    }
                                )).pipe(map(_ => {
                                    data.downloadUrl = url;
                                    data.id = assetDoc.id;
                                    return data;
                                }))
                            })
                        )
                    } else {
                        return of({ id: assetDoc.id, ...data });
                    }
                })
                if (observables.length > 0) {
                    return forkJoin(observables);
                } else {
                    return of([]);
                }
            }),
        );
    }

    delete(asset: Asset): Observable<void> {
        const ref = this.store.collection(`projects/${asset.projectId}/assets`).doc(asset.id)
        return from(ref.delete());
    }
}
