import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from './models/config';
import { SharedDbContextService } from './shared-db-context';

@Injectable()
export class ConfigDb {

    constructor(
        private readonly sharedDbContext: SharedDbContextService,
    ) { 
    }

    getConfig(id: string): Observable<Config> {
        return this.sharedDbContext.getByIdFb(id, 'config');
    }

}
