import { map } from 'rxjs/operators';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly configService: ConfigService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isLoggedIn().pipe(
            map(authInfo => {
                if (authInfo &&
                    authInfo.user &&
                    authInfo.user.uid &&
                    authInfo.userDb &&
                    authInfo.userDb.agreedToTermsAndConditionsVersion === this.configService.configSnapshot.termsAndConditionsVersion &&
                    authInfo.userDb.agreedToPrivacyPolicyVersion === this.configService.configSnapshot.privacyPolicyVersion) {
                    return true;
                } else if (!authInfo.user || !authInfo.user.uid) {
                    this.router.navigate(['auth', 'login']);
                    return false;
                } else {
                    return false;
                }
            })
        );
    }
}