import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth.guard';
import { UpdatedAgreementsComponent } from './updated-agreements/updated-agreements.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { ProfileComponent } from './profile/profile.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MaterialModule } from '../material.module';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AgreementsGuard } from './agreements/agreements.guard';
import { UpdatedAgreementsGuard } from './updated-agreements/updated-agreements.guard';
import { LoggedOutGuard } from './login/logged-out-guard';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { LoggedInGuard } from './login/logged-in-guard';
import { PickPlanComponent } from './pick-plan/pick-plan.component';
import { UserDeletionStepsComponent } from './user-deletion-steps/user-deletion-steps.component';
import { PurchasePlanComponent } from './purchase-plan/purchase-plan.component';
import { UpdatedAgreementsSignedGuard } from './updated-agreements/updated-agreements-signed.guard';
import { HowToDeleteAccountComponent } from './how-to-delete-account/how-to-delete-account.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AuthRoutingModule,
        MaterialModule
    ],
    declarations: [
        AuthComponent,
        LoginComponent,
        SignUpComponent,
        AgreementsComponent,
        ProfileComponent,
        ProfileEditComponent,
        UpdatedAgreementsComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        EmailNotVerifiedComponent,
        UserDeletionStepsComponent,
        PickPlanComponent,
        PurchasePlanComponent,
        HowToDeleteAccountComponent,
        CookiePolicyComponent
    ],
    exports: [
        PickPlanComponent,
    ],
    providers: [
        AuthGuard,
        AgreementsGuard,
        UpdatedAgreementsGuard,
        UpdatedAgreementsSignedGuard,
        LoggedInGuard,
        LoggedOutGuard,
    ]
})
export class AuthModule { }
