<h1>Edit your information</h1>
<form *ngIf="form" [formGroup]="form" (submit)="submit()">
    <div>
        <div>
            <mat-checkbox [required]="false" formControlName="allowEmail">Agree to receive
                news via email
            </mat-checkbox>
        </div>
    </div>
    <button class="form-submit" mat-button mat-primary-button>Submit</button>
</form>