import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommunicationService } from '../shared/communication.service';
import { UserPrivate, UserPublic } from './models/user';
import { SharedDbContextService } from './shared-db-context';

@Injectable()
export class UsersDb {

    constructor(
        private readonly sharedDbContext: SharedDbContextService,
        private readonly store: AngularFirestore,
        private readonly comms: CommunicationService
    ) { }

    getByIdComplete(id: string): Observable<UserPrivate & UserPublic> {
        return forkJoin([
            this.sharedDbContext.getByIdFb(id, `users/${id}/public`),
            this.sharedDbContext.getByIdFb(id, `users/${id}/private`),
        ]).pipe(map((s: any[]) => {
            return { ...s[0], ...s[1] };
        }));
    }

    createUserTransaction(userPublic: UserPublic, userPrivate: UserPrivate) {
        const refPublicUser = this.store.firestore.collection(`users/${userPublic.uid}/public`).doc(`${userPublic.uid}`);
        const refPrivateUser = this.store.firestore.collection(`users/${userPublic.uid}/private`).doc(`${userPublic.uid}`);
        const statsRef = this.store.firestore.collection(`users/${userPrivate.uid}/project-stats`).doc('project-count');
        const publicUserNameRef = this.store.firestore.collection(`publicusernames`).doc(userPublic.publicUserName.toLowerCase())

        return from(this.store.firestore.runTransaction(transaction => {
            transaction.set(refPublicUser, userPublic);
            transaction.set(refPrivateUser, userPrivate);
            transaction.set(publicUserNameRef, { uid: userPublic.uid });
            transaction.set(statsRef, {
                privateCount: 0,
                totalCount: 0,
                totalAssetsSizeMb: 0,
                lastUpdatedId: '',
                uid: userPublic.uid
            });
            return Promise.resolve();
        }));
    }

    publicUserNameExists(publicUserName: string) {
        const docRef = this.store.collection('publicusernames').doc(publicUserName.toLowerCase());
        return docRef.get().pipe(map(s => {
            return s.exists;
        }),
            catchError(_ => of(true)) // if error happens there's something wrong with the users connection and we just say that public is taken
        );
    }

    getPublicUserNameData(publicUserName: string): Observable<UserPublic> {
        const docRef = this.store.collection('publicusernames').doc(publicUserName.toLowerCase());
        return docRef.get().pipe(map(s => {
            return s.data();
        }));
    }

    createPublic(user: UserPublic): Observable<void> {
        const userDocRef = this.store.collection(`users`).doc(`${user.uid}/public/${user.uid}`);
        return from(userDocRef.set(user)).pipe(catchError(err => {
            this.comms.openSnackBar(`Something went wrong. If you have an account, try loggin in instead of signing up. Error message: ${err.message}`, 'Close', true);
            return throwError(err);
        }));
    }

    createPrivate(user: UserPrivate): Observable<void> {
        const userDocRef = this.store.collection('users').doc(`${user.uid}/private/${user.uid}`);
        return from(userDocRef.set(user)).pipe(catchError(err => {
            this.comms.openSnackBar(`Something went wrong. If you have an account, try loggin in instead of signing up. Error message: ${err.message}`, 'Close', true);
            return throwError(err);
        }));
    }

    updatePrivate(user: UserPrivate, id): Observable<UserPrivate> {
        return this.sharedDbContext.createOrUpdateFb<UserPrivate>(user, `users/${id}/private`, id);
    }

    updatePublic(user: UserPublic, id): Observable<UserPublic> {
        return this.sharedDbContext.createOrUpdateFb<UserPublic>(user, `users/${id}/public`, id);
    }

    deleteFb(id: string): Observable<void> {
        return this.sharedDbContext.deleteFb(id, 'users');
    }
}
